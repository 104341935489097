import React from "react"
import NumberFormat from "react-number-format"
import PropTypes from "prop-types"

export const InputThousandSeparator = React.forwardRef(
  function InputThousandSeparator(props, ref) {
    const { onChange, prefix, value, ...other } = props

    return (
      <NumberFormat
        {...other}
        decimalScale={2}
        getInputRef={ref}
        allowNegative={false}
        isNumericString={true}
        value={value}
        onValueChange={(values) => {
          onChange({
            target: {
              value: values.value,
              formattedValue: values.formattedValue,
            },
          })
        }}
        prefix={prefix}
      />
    )
  }
)

InputThousandSeparator.propTypes = {
  onChange: PropTypes.func.isRequired,
  prefix: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export const TextThousandSeparator = React.forwardRef(
  function TextThousandSeparator(props, ref) {
    const { onChange, ...other } = props

    return <NumberFormat {...other} decimalScale={2} displayType="text" />
  }
)

export const numFormatter = (num) => {
  if (num > 999 && num < 1000000) {
    return (num / 1000).toFixed(1) + "K" // convert to K for number from > 1000 < 1 million
  } else if (num > 1000000) {
    return (num / 1000000).toFixed(1) + "M" // convert to M for number from > 1 million
  } else if (num < 900) {
    return num // if value < 1000, nothing to do
  }
}

// To display formatted value
export const displayValue = (
  value,
  ext = "$",
  thousandSeparator = true,
  isSuffix = false,
  fixedDecimalScale = false,
  enableScaling = true
) => {
  // Ensure value is a number or can be converted to a number
  if (typeof value === "string") {
    value = parseFloat(value) // Attempt to convert string to a number
  }
  if (isNaN(value)) return "-" // Return "-" if value is not a valid number

  if (!value) return "-"
  if (isSuffix) {
    value = value.toFixed(2)
  } else {
    const absValue = Math.abs(value)
    if (enableScaling) {
      if (absValue < 1000) {
        value = value.toFixed(2)
      } else if (absValue >= 1000 && absValue <= 1000000) {
        // convert to K for number from > 1000 < 1 million
        value = (value / 1000).toFixed(2)
        isSuffix = true
        ext = "K"
      } else if (absValue >= 1000000 && absValue < 1000000000) {
        // convert to K for number from > 1000 < 1 million
        value = (value / 1000000).toFixed(2)
        isSuffix = true
        ext = "M"
      } else if (absValue >= 1000000000 && absValue < 1000000000000) {
        // convert to K for number from > 1000 < 1 million
        value = (value / 1000000000).toFixed(2)
        isSuffix = true
        ext = "B"
      } else if (absValue >= 1000000000000) {
        // convert to K for number from > 1000 < 1 million
        value = (value / 1000000000000).toFixed(2)
        isSuffix = true
        ext = "T"
      }
    }
  }

  return (
    <TextThousandSeparator
      value={value}
      prefix={!isSuffix && ext}
      suffix={isSuffix && ext}
      thousandSeparator={thousandSeparator}
      fixedDecimalScale={fixedDecimalScale}
    />
  )
}

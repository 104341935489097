import React, { useContext, useEffect } from "react"
import {
  Box,
  Grid,
  Link,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { useParams } from "react-router-dom"

import useStyles from "./styles"
import AppDataContext from "../../../context/data"
import BigNumbers from "./components/BigNumbers"
import ShareStatistics from "./components/ShareStatistics"
import PerShareNumbers from "./components/PerShareNumbers"
import MarginsProfitability from "./components/MarginsProfitability"
import FinancialHealth from "./components/FinancialHealth"
import ManagementEffectiveness from "./components/ManagementEffectiveness"
import CurrentYields from "./components/CurrentYields"
import GrowthRates from "./components/GrowthRates"
import PriceMultiples from "./components/PriceMultiples"
import EnterpriseValueMultiples from "./components/EnterpriseValueMultiples"
import HistoricalPriceChart from "./components/HistoricalPriceChart"
import ReactGA from "react-ga4"
import Profile from "./components/Profile"
import News from "./components/News"

const Overview = () => {
  ReactGA.send({ hitType: "pageview", page: "/ticker/overview" })
  const { overview } = useContext(AppDataContext)
  const { tickerId } = useParams()
  const videoInfo = {
    title: "Watch the Tutorial about this page on YouTube",
    url: "https://www.youtube.com/watch?v=ngJIoVhatQ0",
  }

  const classes = useStyles()
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm")) // Adjust based on your breakpoints
  const isLargeUp = useMediaQuery(theme.breakpoints.up("lg"))
  const isMediumUp = useMediaQuery(theme.breakpoints.up("md"))
  const isMediumDown = useMediaQuery(theme.breakpoints.down("md"))
  const isXsDown = useMediaQuery(theme.breakpoints.down("sm"))

  useEffect(() => {
    document.title = `${tickerId} - Overview - Lets Value Invest`
  }, [])

  return (
    <Box className={classes["ticker-container"]}>
      <Grid
        container
        rowSpacing={3}
        columnSpacing={2}
        sx={(theme) => ({
          ...(isSmallScreen && {
            "@media (min-width: 0px)": {
              width: "calc(100% + 55px)",
              marginLeft: "-35px",
            },
          }),
        })}
      >
        <Grid item sm={12}>
          <Tooltip title={`${videoInfo.title}`}>
            <Link
              href={videoInfo.url}
              target="_blank"
              rel="noopener noreferrer"
              variant="body2"
              sx={{ fontStyle: "italic" }}
            >
              Learn More
            </Link>
          </Tooltip>
        </Grid>

        {/* First row: BigNumbers and HistoricalPriceChart */}
        {isLargeUp ? (
          <>
            <Grid item xs={12} sm={3}>
              <BigNumbers data={overview?.bigNumbers || {}} />
            </Grid>
            <Grid item xs={12} sm={9}>
              <Grid container display="flex" justifyContent="center">
                <Grid item xs={12} sm={10}>
                  <HistoricalPriceChart
                    historicalPriceFull={overview?.historicalPriceFull || []}
                    historicalPrice5Min={overview?.historicalPrice5Min || []}
                    overview={overview || {}}
                  />
                </Grid>
              </Grid>
            </Grid>
          </>
        ) : (
          <Grid item xs={12}>
            <Grid container display="flex" justifyContent="center">
              <Grid item xs={12} sm={10}>
                <HistoricalPriceChart
                  historicalPriceFull={overview?.historicalPriceFull || []}
                  historicalPrice5Min={overview?.historicalPrice5Min || []}
                  overview={overview || {}}
                />
              </Grid>
            </Grid>
          </Grid>
        )}

        {/* ShareStatistics, PerShareNumbers, MarginsProfitability, etc. for Large/Extra Large Screens */}
        {isLargeUp && (
          <>
            <Grid item xs={12} sm={9}>
              <Grid container rowSpacing={3} columnSpacing={2}>
                <Grid item xs={12} sm={4}>
                  <ShareStatistics data={overview?.shareStats || {}} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <PerShareNumbers data={overview?.perShareNumbers || {}} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <MarginsProfitability data={overview?.margins || {}} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FinancialHealth data={overview?.financialHealth || {}} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <ManagementEffectiveness
                    data={overview?.managementEffectiveness || {}}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <CurrentYields data={overview?.currentYields || {}} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <GrowthRates data={overview?.growthRates || {}} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <PriceMultiples data={overview?.valuationMultiples || {}} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <EnterpriseValueMultiples
                    data={overview?.enterpriseValueMultiples || {}}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Profile data={overview?.profile || {}} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={3}>
              <News
                name={overview?.news?.name}
                data={overview?.news?.data || []}
              />
            </Grid>
          </>
        )}

        {/* BigNumbers, ShareStatistics, PerShareNumbers, etc. for Medium Screens and above but less than large */}
        {isMediumUp && !isLargeUp && (
          <>
            {/* BigNumbers */}
            <Grid item xs={12} sm={6}>
              <BigNumbers data={overview?.bigNumbers || {}} />
            </Grid>
            {/* ShareStatistics */}
            <Grid item xs={12} sm={6}>
              <ShareStatistics data={overview?.shareStats || {}} />
            </Grid>
            {/* PerShareNumbers */}
            <Grid item xs={12} sm={6}>
              <PerShareNumbers data={overview?.perShareNumbers || {}} />
            </Grid>
            {/* MarginsProfitability */}
            <Grid item xs={12} sm={6}>
              <MarginsProfitability data={overview?.margins || {}} />
            </Grid>
            {/* FinancialHealth */}
            <Grid item xs={12} sm={6}>
              <FinancialHealth data={overview?.financialHealth || {}} />
            </Grid>
            {/* ManagementEffectiveness */}
            <Grid item xs={12} sm={6}>
              <ManagementEffectiveness
                data={overview?.managementEffectiveness || {}}
              />
            </Grid>
            {/* CurrentYields */}
            <Grid item xs={12} sm={6}>
              <CurrentYields data={overview?.currentYields || {}} />
            </Grid>
            {/* GrowthRates */}
            <Grid item xs={12} sm={6}>
              <GrowthRates data={overview?.growthRates || {}} />
            </Grid>
            {/* PriceMultiples */}
            <Grid item xs={12} sm={6}>
              <PriceMultiples data={overview?.valuationMultiples || {}} />
            </Grid>
            {/* EnterpriseValueMultiples */}
            <Grid item xs={12} sm={6}>
              <EnterpriseValueMultiples
                data={overview?.enterpriseValueMultiples || {}}
              />
            </Grid>
            {/* Profile */}
            <Grid item xs={12} sm={6}>
              <Profile data={overview?.profile || {}} />
            </Grid>
          </>
        )}
        {/* News for Large/Medium Screens */}
        {isMediumUp && !isLargeUp && (
          <Grid item xs={12}>
            <News
              name={overview?.news?.name}
              data={overview?.news?.data || []}
            />
          </Grid>
        )}
        {/* BigNumbers, ShareStatistics, PerShareNumbers, etc. for Medium/Small Screens */}
        {isMediumDown && (
          <>
            {/* BigNumbers */}
            <Grid item xs={12} sm={6}>
              <BigNumbers data={overview?.bigNumbers || {}} />
            </Grid>
            {/* ShareStatistics */}
            <Grid item xs={12} sm={6}>
              <ShareStatistics data={overview?.shareStats || {}} />
            </Grid>
            {/* PerShareNumbers */}
            <Grid item xs={12} sm={6}>
              <PerShareNumbers data={overview?.perShareNumbers || {}} />
            </Grid>
            {/* MarginsProfitability */}
            <Grid item xs={12} sm={6}>
              <MarginsProfitability data={overview?.margins || {}} />
            </Grid>
            {/* FinancialHealth */}
            <Grid item xs={12} sm={6}>
              <FinancialHealth data={overview?.financialHealth || {}} />
            </Grid>
            {/* ManagementEffectiveness */}
            <Grid item xs={12} sm={6}>
              <ManagementEffectiveness
                data={overview?.managementEffectiveness || {}}
              />
            </Grid>
            {/* CurrentYields */}
            <Grid item xs={12} sm={6}>
              <CurrentYields data={overview?.currentYields || {}} />
            </Grid>
            {/* GrowthRates */}
            <Grid item xs={12} sm={6}>
              <GrowthRates data={overview?.growthRates || {}} />
            </Grid>
            {/* PriceMultiples */}
            <Grid item xs={12} sm={6}>
              <PriceMultiples data={overview?.valuationMultiples || {}} />
            </Grid>
            {/* EnterpriseValueMultiples */}
            <Grid item xs={12} sm={6}>
              <EnterpriseValueMultiples
                data={overview?.enterpriseValueMultiples || {}}
              />
            </Grid>
            {/* Profile */}
            <Grid item xs={12} sm={6}>
              <Profile data={overview?.profile || {}} />
            </Grid>
          </>
        )}
        {/* News for Medium/Small Screens */}
        {isMediumDown && (
          <Grid item xs={12}>
            <News
              name={overview?.news?.name}
              data={overview?.news?.data || []}
            />
          </Grid>
        )}

        {/* BigNumbers, ShareStatistics, PerShareNumbers, etc. for XS Screens */}
        {isXsDown && !isMediumDown && (
          <>
            {/* BigNumbers */}
            <Grid item xs={12}>
              <BigNumbers data={overview?.bigNumbers || {}} />
            </Grid>
            {/* ShareStatistics */}
            <Grid item xs={12}>
              <ShareStatistics data={overview?.shareStats || {}} />
            </Grid>
            {/* PerShareNumbers */}
            <Grid item xs={12}>
              <PerShareNumbers data={overview?.perShareNumbers || {}} />
            </Grid>
            {/* MarginsProfitability */}
            <Grid item xs={12}>
              <MarginsProfitability data={overview?.margins || {}} />
            </Grid>
            {/* FinancialHealth */}
            <Grid item xs={12}>
              <FinancialHealth data={overview?.financialHealth || {}} />
            </Grid>
            {/* ManagementEffectiveness */}
            <Grid item xs={12}>
              <ManagementEffectiveness
                data={overview?.managementEffectiveness || {}}
              />
            </Grid>
            {/* CurrentYields */}
            <Grid item xs={12}>
              <CurrentYields data={overview?.currentYields || {}} />
            </Grid>
            {/* GrowthRates */}
            <Grid item xs={12}>
              <GrowthRates data={overview?.growthRates || {}} />
            </Grid>
            {/* PriceMultiples */}
            <Grid item xs={12}>
              <PriceMultiples data={overview?.valuationMultiples || {}} />
            </Grid>
            {/* EnterpriseValueMultiples */}
            <Grid item xs={12}>
              <EnterpriseValueMultiples
                data={overview?.enterpriseValueMultiples || {}}
              />
            </Grid>
            {/* Profile */}
            <Grid item xs={12}>
              <Profile data={overview?.profile || {}} />
            </Grid>
          </>
        )}
        {/* News for XS Screens */}
        {isXsDown && !isMediumDown && (
          <Grid item xs={12}>
            <News
              name={overview?.news?.name}
              data={overview?.news?.data || []}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  )
}

export default Overview

import React, { useContext, useEffect, useState } from "react"
import { Box, Grid, Typography, Tooltip, Link } from "@mui/material"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"

import useStyles from "./styles"
import AppDataContext from "../../../context/data"
import { getFingraphs } from "../../../services/ticker"
import Loading from "../../Loading"
import ReactGA from "react-ga4"
import Revenue from "./components/IncomeStatement/Revenue"
import GrossProfit from "./components/IncomeStatement/GrossProfit"
import OperatingIncome from "./components/IncomeStatement/OperatingIncome"
import OpExpensesAsPercentOfGross from "./components/IncomeStatement/OpExpensesAsPercentOfGross"
import OpIncomeAndNonOpIncomes from "./components/IncomeStatement/OpIncomeAndNonOpIncomes"
import NetIncomeAndTaxes from "./components/IncomeStatement/NetIncomeAndTaxes"
import NetIncome from "./components/IncomeStatement/NetIncome"
import SharesOutstanding from "./components/IncomeStatement/SharesOutstanding"
import EPS from "./components/IncomeStatement/EPS"
import BalanceSheetSummary from "./components/BalanceSheet/BalanceSheetSummary"
import DebtAndCashPosition from "./components/BalanceSheet/DebtAndCashPosition"
import GoodwillAndIntangiblesAssets from "./components/BalanceSheet/GoodwillAndIntangiblesAssets"
import StockHoldersEquity from "./components/BalanceSheet/StockHoldersEquity"
import OperatingCashflow from "./components/CashFlowStatement/OperatingCashflow"
import FreeCashflow from "./components/CashFlowStatement/FreeCashflow"
import FCFSummary from "./components/CashFlowStatement/FCFSummary"
import FCFUsage from "./components/CashFlowStatement/FCFUsage"
import AdjustedFCF from "./components/CashFlowStatement/AdjustedFCF"

const Fingraphs = () => {
  ReactGA.send({
    hitType: "pageview",
    page: "/ticker/financial-graphs",
  })
  const [isLoading, setIsLoading] = useState(false)
  const [fingraphsData, setFingraphsData] = useState(null)
  const { tickerId } = useParams()
  const { fingraphs, setFingraphs, overview } = useContext(AppDataContext)

  const videoInfo = {
    // use context or the simple object
    title: "Watch the Tutorial about this page on YouTube",
    url: "https://www.youtube.com/watch?v=RSInwYTXTbg", // Replace with your actual URL
  }

  useEffect(() => {
    document.title = `${tickerId} - Fingraphs - Lets Value Invest`
    setIsLoading(true)
    const getFingraphsData = async () => {
      toast.dismiss()
      try {
        // get data only if context is empty and save into context
        const resp = await getFingraphs(tickerId)
        setFingraphsData({ ...resp?.data, tickerId })
        setFingraphs({ ...resp.data, tickerId })
        setIsLoading(false)
      } catch (err) {
        setFingraphs(null)
        setIsLoading(false)
        toast.error(
          "Unable to get fingraphs information. Please try after sometime."
        )
      }
    }
    // check if we have data already in context
    if (fingraphs?.tickerId === tickerId) {
      setFingraphsData(fingraphs)
      setIsLoading(false)
      return
    }
    overview?.tickerId && getFingraphsData()
  }, [JSON.stringify(overview), tickerId])

  if (isLoading) {
    return (
      <Box className="loader">
        <Loading />
      </Box>
    )
  }
  if (!fingraphsData) return <></>

  return (
    <Grid
      container
      rowSpacing={4}
      columnSpacing={{ xs: 1, sm: 2, md: 5 }}
      style={{ padding: "0 1rem" }}
    >
      <Grid item sm={12}>
        <Tooltip title={`${videoInfo.title}`}>
          <Link
            href={videoInfo.url}
            target="_blank" // Open in new tab
            rel="noopener noreferrer" // Important for security when opening new tabs
            variant="body2"
            sx={{ fontStyle: "italic" }}
          >
            Learn More
          </Link>
        </Tooltip>
      </Grid>
      <Grid item xs={12}>
        <Typography>
          Our carefully curated selection of graphs offer a clear and efficient
          glimpse into the company's financials. They provide a quick and
          visually appealing overview of key financial information, allowing you
          to make informed decisions with ease.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Revenue
          incomeStatement={{
            ...fingraphsData?.incomeStatementData,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <GrossProfit
          incomeStatement={{
            ...fingraphsData?.incomeStatementData,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <OpExpensesAsPercentOfGross
          incomeStatement={{
            ...fingraphsData?.incomeStatementData,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <OperatingIncome
          incomeStatement={{
            ...fingraphsData?.incomeStatementData,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <OpIncomeAndNonOpIncomes
          incomeStatement={{
            ...fingraphsData?.incomeStatementData,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <NetIncomeAndTaxes
          incomeStatement={{
            ...fingraphsData?.incomeStatementData,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <NetIncome
          incomeStatement={{
            ...fingraphsData?.incomeStatementData,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <SharesOutstanding
          incomeStatement={{
            ...fingraphsData?.incomeStatementData,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <EPS
          incomeStatement={{
            ...fingraphsData?.incomeStatementData,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <BalanceSheetSummary
          balanceSheetStatement={{
            ...fingraphsData?.balanceSheetStatementData,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DebtAndCashPosition
          balanceSheetStatement={{
            ...fingraphsData?.balanceSheetStatementData,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <GoodwillAndIntangiblesAssets
          balanceSheetStatement={{
            ...fingraphsData?.balanceSheetStatementData,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <StockHoldersEquity
          balanceSheetStatement={{
            ...fingraphsData?.balanceSheetStatementData,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <OperatingCashflow
          cashFlowStatement={{
            ...fingraphsData?.cashFlowStatementData,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FreeCashflow
          cashFlowStatement={{
            ...fingraphsData?.cashFlowStatementData,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FCFSummary
          cashFlowStatement={{
            ...fingraphsData?.cashFlowStatementData,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FCFUsage
          cashFlowStatement={{
            ...fingraphsData?.cashFlowStatementData,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <AdjustedFCF
          cashFlowStatement={{
            ...fingraphsData?.cashFlowStatementData,
          }}
        />
      </Grid>
    </Grid>
  )
}

export default Fingraphs

//Dashboard.js
import React, { useContext } from "react"
import { Box, Typography, Link } from "@mui/material"
import { useNavigate } from "react-router-dom"
import Search from "../Search"
import useStyles from "./styles"
import AppDataContext from "../../context/data"

const Dashboard = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { recentSearches } = useContext(AppDataContext)

  return (
    <Box className={classes["content-container"]} flex={1}>
      <Typography className={classes["header-text"]}>
        Welcome to <b>Lets Value Invest</b>. Start by searching your favorite
        company using ticker or the company name.
      </Typography>
      <Box className={classes["search-area"]}>
        <Search />
      </Box>
      <Box className={classes["recent-search-box"]}>
        {recentSearches && recentSearches.length > 0 && (
          <Typography className={classes["recent-search"]}>
            Recent Searches:{" "}
          </Typography>
        )}
        {recentSearches &&
          recentSearches
            .slice(-10)
            .reverse()
            .map((text, index) => (
              <Link
                key={`${text}-${index}`}
                className={classes["recent-search-txt"]}
                onClick={() => {
                  navigate(`/ticker/${text}/overview`)
                }}
              >
                {text}
              </Link>
            ))}
      </Box>
    </Box>
  )
}

export default Dashboard

import React, { useContext, useEffect, useState } from "react"
import { Box, Typography, Grid, IconButton, Tooltip } from "@mui/material"
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"

import Joyride from "react-joyride"
import AppDataContext from "../../context/data"
import useStyles from "./styles"
import { useNavigate } from "react-router-dom"
import { displayValue } from "../../utils/NumberFormatters"
import ReactGA from "react-ga4"
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from "@mui/x-data-grid"
import Loading from "../Loading"
import News from "../Ticker/Overview/components/News"
import Ticker from "../Chip/Ticker"
import Rating from "../Chip/Rating"
import Moat from "../Chip/Moat"
import EarningsCalendar from "./components/EarningsCalendar"
import { getWatchlists, createWatchlist } from "../../services/user"

function Watchlists() {
  const classes = useStyles()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(true)
  const [news, setNews] = useState([])
  const [earningsCalendar, setEarningsCalendar] = useState([])
  const [tickers, setTickers] = useState([])
  const { watchlists, setWatchlists } = useContext(AppDataContext)
  const [runTour, setRunTour] = useState(false) // Controls the tour run state

  ReactGA.send({
    hitType: "pageview",
    page: "/watchlists",
  })

  useEffect(() => {
    setIsLoading(true)
    // getWatchlists
    const getUsersWatchlists = async () => {
      const userId = window.sessionStorage.getItem("userId")
      try {
        let resp = await getWatchlists(userId)
        // if there are no watchlists then create the default one
        if (resp?.data?.length === 0) {
          resp = await createWatchlist(
            userId,
            JSON.stringify({
              name: "Default",
            })
          )
          setWatchlists([resp?.data])
        } else {
          setWatchlists(resp?.data)
        }
      } catch (err) {}
    }
    getUsersWatchlists()
    setIsLoading(false)
  }, [setWatchlists])

  const handleTourStart = () => {
    setRunTour(true)
  }

  const handleTourEnd = (data) => {
    const { status } = data
    const finishedStatuses = ["finished", "skipped"]
    if (finishedStatuses.includes(status)) {
      setRunTour(false) // Reset the tour state after completion
      window.scrollTo({ top: 0, behavior: "smooth" })
    }
  }

  const handleRowClick = (tickerId) => {
    navigate(`/ticker/${tickerId}/overview`)
  }

  useEffect(() => {
    document.title = `Watchlists - Lets Value Invest`
  }, [])

  const columns = [
    {
      field: "tickerId",
      headerName: "Ticker",
      description: "Stock ticker",
      width: 100,
      renderCell: (params) => {
        return <Ticker key={params.row.tickerId} ticker={params.row.tickerId} />
      },
    },
    {
      field: "name",
      headerName: "Name",
      description: "Company name",
      width: 250,
      renderCell: (params) => {
        return (
          <Tooltip
            title={params.row.name}
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: "offset",
                    options: {
                      offset: [0, -30],
                    },
                  },
                ],
              },
            }}
          >
            <Box className={classes["company-name"]}>{params.row.name}</Box>
          </Tooltip>
        )
      },
    },
    {
      field: "overallScore",
      headerName: "LVI Rating",
      description:
        "Overall rating assigned by LVI based on various factors like valuation, growth, quality, management etc.",
      sortable: true,
      filterable: false,
      width: 125,
      renderCell: (params) => {
        return <Rating score={params.row.overallScore} />
      },
    },
    {
      field: "growthScore",
      headerName: "Growth Rates",
      description:
        "Historical growth rate based on analysis of past revenue, income, and other key metrics.",
      sortable: true,
      filterable: false,
      width: 125,
      renderCell: (params) => {
        return <Rating score={params.row.growthScore} />
      },
    },
    {
      field: "managementEffectivenessScore",
      headerName: "Management Effectiveness",
      description:
        "Management's commitment to shareholder value, as measured by historical ROA, ROE, ROCE, and share repurchases.",
      sortable: true,
      filterable: false,
      width: 125,
      renderCell: (params) => {
        return <Rating score={params.row.managementEffectivenessScore} />
      },
    },
    {
      field: "financialHealthScore",
      headerName: "Financial Health",
      description: "Company's ability to pay off its debt.",
      sortable: true,
      filterable: false,
      width: 150,
      renderCell: (params) => {
        return <Rating score={params.row.financialHealthScore} />
      },
    },
    {
      field: "valuationMultiplesScore",
      headerName: "Valuation",
      description:
        "How cheap or expensive the company is compared to its past valuations.",
      sortable: true,
      filterable: false,
      width: 125,
      renderCell: (params) => {
        return <Rating score={params.row.valuationMultiplesScore} />
      },
    },
    {
      field: "profitabilityScore",
      headerName: "Profitability",
      description: "Company's ability to convert sales into profit",
      sortable: true,
      filterable: false,
      width: 125,
      renderCell: (params) => {
        return <Rating score={params.row.profitabilityScore} />
      },
    },
    {
      field: "moatScore",
      headerName: "MOAT",
      description:
        "LVI's proprietary analysis of the company's historical data to assess its competitive advantage (MOAT).",
      sortable: true,
      filterable: false,
      width: 125,
      renderCell: (params) => {
        return <Moat score={params.row.moatScore} />
      },
    },
    {
      field: "businessQualityScore",
      headerName: "Quality",
      description:
        "LVI's proprietary analysis of the company's historical data to assess the business quality.",
      sortable: true,
      filterable: false,
      width: 125,
      renderCell: (params) => {
        return <Rating score={params.row.businessQualityScore} />
      },
    },
    {
      field: "dividendScore",
      headerName: "Dividend",
      description:
        "Assessment of the company's dividend-paying ability and sustainability.",
      sortable: true,
      filterable: false,
      width: 125,
      renderCell: (params) => {
        return <Rating score={params.row.dividendScore} />
      },
    },
    {
      field: "price",
      headerName: "Price",
      description: "Current market price of the stock.",
      sortable: true,
      filterable: false,
      type: "number",
      width: 100,
      alignProperty: "right",
      renderCell: (params) => {
        const formattedChange = displayValue(params.row.price, "$", true, false)
        return <div>{formattedChange}</div>
      },
    },
    {
      field: "change",
      headerName: "Change",
      description: "Change in price compared to the previous closing price.",
      sortable: true,
      filterable: false,
      width: 100,
      renderCell: (params) => {
        const formattedChange = displayValue(
          params.row.change,
          "",
          false,
          false
        )
        return (
          <div
            className={`${classes.priceChangePer} ${
              params.row?.change < 0 ? "error-txt" : "success-txt"
            }`}
          >
            {formattedChange}
          </div>
        )
      },
    },
    {
      field: "changesPercentage",
      headerName: "Change %",
      description:
        "Percentage change in price compared to the previous closing price.",
      sortable: true,
      filterable: false,
      width: 100,
      renderCell: (params) => {
        const formattedChange = displayValue(
          params.row.changesPercentage,
          "%",
          true,
          true
        )
        return (
          <div
            className={`${classes.priceChangePer} ${
              params.row?.change < 0 ? "error-txt" : "success-txt"
            }`}
          >
            {formattedChange}
          </div>
        )
      },
    },
    {
      field: "yearLow",
      headerName: "52 week low",
      description: "52 week low price.",
      sortable: true,
      filterable: false,
      width: 125,
      alignProperty: "right",
      renderCell: (params) => {
        const formattedChange = displayValue(
          params.row.yearLow,
          "$",
          true,
          false
        )
        return <div>{formattedChange}</div>
      },
    },
    {
      field: "percentChangeFromYearLow",
      headerName: "% Chg from 52 week low",
      description:
        "Percentage change in price compared to the 52 week low price.",
      sortable: true,
      filterable: false,
      width: 150,
      renderCell: (params) => {
        const formattedChange = displayValue(
          params.row.percentChangeFromYearLow,
          "%",
          true,
          true
        )
        return (
          <div
            className={`${classes.priceChangePer} ${
              params.row?.percentChangeFromYearLow < 0
                ? "error-txt"
                : "success-txt"
            }`}
          >
            {formattedChange}
          </div>
        )
      },
    },
    {
      field: "yearHigh",
      headerName: "52 week high",
      description: "52 week high price.",
      sortable: true,
      filterable: false,
      width: 125,
      alignProperty: "right",
      renderCell: (params) => {
        const formattedChange = displayValue(
          params.row.yearHigh,
          "$",
          true,
          false
        )
        return <div>{formattedChange}</div>
      },
    },
    {
      field: "percentChangeFromYearHigh",
      headerName: "% Chg from 52 week high",
      description:
        "Percentage change in price compared to the 52 week high price.",
      sortable: true,
      filterable: false,
      width: 150,
      renderCell: (params) => {
        const formattedChange = displayValue(
          params.row.percentChangeFromYearHigh,
          "%",
          true,
          true
        )
        return (
          <div
            className={`${classes.priceChangePer} ${
              params.row?.percentChangeFromYearHigh < 0
                ? "error-txt"
                : "success-txt"
            }`}
          >
            {formattedChange}
          </div>
        )
      },
    },
    {
      field: "ytdReturn",
      headerName: "YTD return",
      description: "The stock's performance so far this year.",
      sortable: true,
      filterable: false,
      width: 100,
      renderCell: (params) => {
        const formattedChange = displayValue(
          params.row.ytdReturn,
          "%",
          true,
          true
        )
        return (
          <div
            className={`${classes.priceChangePer} ${
              params.row?.ytdReturn < 0 ? "error-txt" : "success-txt"
            }`}
          >
            {formattedChange}
          </div>
        )
      },
    },
    {
      field: "yearlyReturn",
      headerName: "1 year return",
      description: "The stock's performance in the last 1 year.",
      sortable: true,
      filterable: false,
      width: 125,
      renderCell: (params) => {
        const formattedChange = displayValue(
          params.row.yearlyReturn,
          "%",
          true,
          true
        )
        return (
          <div
            className={`${classes.priceChangePer} ${
              params.row?.yearlyReturn < 0 ? "error-txt" : "success-txt"
            }`}
          >
            {formattedChange}
          </div>
        )
      },
    },
    {
      field: "fiveYearReturn",
      headerName: "5 years return",
      description: "The stock's performance in the last 5 years.",
      sortable: true,
      filterable: false,
      width: 150,
      renderCell: (params) => {
        const formattedChange = displayValue(
          params.row.fiveYearReturn,
          "%",
          true,
          true
        )
        return (
          <div
            className={`${classes.priceChangePer} ${
              params.row?.fiveYearReturn < 0 ? "error-txt" : "success-txt"
            }`}
          >
            {formattedChange}
          </div>
        )
      },
    },
  ]

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        {/* <GridToolbarFilterButton /> */}
        <GridToolbarDensitySelector
          slotProps={{ tooltip: { title: "Change density" } }}
        />
        <Box sx={{ flexGrow: 1 }} />
        <GridToolbarExport
          slotProps={{
            tooltip: { title: "Export data in CSV format" },
          }}
        />
      </GridToolbarContainer>
    )
  }

  useEffect(() => {
    if (watchlists.length > 0) {
      setTickers(watchlists[0]?.tickers || [])
      setEarningsCalendar(watchlists[0]?.earningsCalendar || [])
      setNews(watchlists[0]?.news || [])
    }
  }, [watchlists])

  const steps = [
    {
      target: ".joyride-datagrid", // Assuming your DataGrid has this class
      content:
        'This is your watchlist. There are more columns available than you see. Just click on the "COLUMNS" button above and select columns to display.',
      placement: "top",
      disableBeacon: true,
    },
    {
      target: ".joyride-earnings-calendar", // Assuming your Earnings Calendar section has this class
      content:
        "This section displays the upcoming earnings calendar for stocks in your watchlist.",
      placement: "top",
      disableBeacon: true,
    },
    {
      target: ".joyride-news", // Assuming your News section has this class
      content:
        "This section shows the latest news related to the stocks in your watchlist.",
      placement: "top",
      disableBeacon: true,
    },
  ]

  if (!watchlists.length || isLoading) {
    return (
      <Box className="loader">
        <Loading />
      </Box>
    )
  }

  return (
    <React.Fragment>
      <Joyride
        steps={steps}
        run={runTour}
        continuous // Automatically move to the next step
        scrollToFirstStep
        showProgress // Show progress indicator
        showSkipButton // Show a "Skip" button
        hideBackButton
        debug={true}
        // disableScrolling
        callback={handleTourEnd} // Detect when the tour finishes
        locale={{
          last: "Done", // Rename "Last" button to "Done"
          next: "Next", // Customize other button text if needed
          back: "Back",
          skip: "Skip",
        }}
        styles={{
          options: {
            arrowColor: "#007BFF", // Matches the box background
            backgroundColor: "#007BFF", // Blue background for the Joyride box
            primaryColor: "#FFFFFF", // White for buttons like "Next" or "Skip"
            textColor: "#FFFFFF", // White text for the content
            zIndex: 1000, // Ensure it stays on top
            textAlign: "left",
          },
          tooltipContainer: {
            textAlign: "left",
            fontSize: "0.90rem",
          },
          buttonNext: {
            backgroundColor: "#004085", // Darker blue for "Next" button
            color: "#FFFFFF", // White text for "Next" button
          },
        }}
      />
      <Box className={classes["main-container"]}>
        <Grid container columnSpacing={2} rowSpacing={2}>
          <Grid item xs={12} sx={{ mb: -4 }}>
            <p>
              Simplify your investment journey. Our watchlist tool helps you
              organize your favorite stocks, track their performance, and stay
              updated with the latest news and analysis.{" "}
            </p>
          </Grid>
          {watchlists[0]?.tickers?.length > 0 ? (
            <>
              <Tooltip title="Quick Tour">
                <IconButton
                  onClick={handleTourStart}
                  sx={{ position: "absolute", top: 64, right: 16 }}
                >
                  <HelpOutlineIcon sx={{ fontSize: "36px" }} />
                </IconButton>
              </Tooltip>
              <Grid item xs={12}>
                <h3>
                  You are following {watchlists[0]?.tickers?.length} tickers:
                </h3>
                <Typography className={classes.momentAgo} variant="body1">
                  Price related data is not real time and is updated every 24
                  hours.
                </Typography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <Box sx={{ width: "100%" }}>
                  <DataGrid
                    className="joyride-datagrid"
                    rows={tickers}
                    columns={columns}
                    hideFooterPagination={true}
                    initialState={{
                      columns: {
                        columnVisibilityModel: {
                          // Hide columns status and traderName, the other columns will remain visible
                          growthScore: false,
                          managementEffectivenessScore: false,
                          financialHealthScore: false,
                          profitabilityScore: false,
                          valuationMultiplesScore: false,
                          dividendScore: false,
                          fiveYearReturn: false,
                          yearLow: false,
                          percentChangeFromYearLow: false,
                          yearHigh: false,
                          percentChangeFromYearHigh: false,
                        },
                      },
                    }}
                    sx={{
                      "& .MuiDataGrid-row": {
                        cursor: "pointer",
                        "&:hover": { backgroundColor: "#f8f9fa" },
                      },
                    }}
                    onRowClick={(params) => {
                      handleRowClick(params.row.tickerId)
                    }}
                    slots={{
                      toolbar: CustomToolbar,
                      loadingOverlay: {
                        variant: "linear-progress",
                        noRowsVariant: "skeleton",
                      },
                    }}
                    hideFooter
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Grid container spacing={2}>
                  <Grid item xs={12} className="joyride-earnings-calendar">
                    <EarningsCalendar
                      key={JSON.stringify(earningsCalendar)}
                      data={earningsCalendar}
                    />
                  </Grid>
                  <Grid item xs={12} className="joyride-news">
                    <News key={JSON.stringify(news)} data={news} />
                  </Grid>
                </Grid>
              </Grid>
            </>
          ) : (
            <Grid item xs={12}>
              <Box
                sx={{
                  p: { xs: 2, md: 2 },
                  m: { xs: 2, md: 2 },
                  border: "1px dashed grey",
                  width: "50%",
                }}
              >
                <Typography>
                  You are not following any tickers yet. Please search some of
                  your favourite tickers and start following.
                </Typography>
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
    </React.Fragment>
  )
}

export default Watchlists

import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import {
  Box,
  Paper,
  Typography,
  Stack,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from "@mui/material"
import { useTheme } from "@mui/material/styles"
import { useParams } from "react-router-dom"
import moment from "moment"
import useStyles from "../styles"
import { Chart } from "react-chartjs-2"
import {
  Chart as ChartJS,
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
  Tooltip,
  Legend,
} from "chart.js"

ChartJS.register(
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
  Tooltip,
  Legend
)

const PriceTargets = ({ priceTargets = {}, historicalPrices = [] }) => {
  const theme = useTheme()
  const classes = useStyles()
  const { tickerId } = useParams()
  const chartRef = useRef(null)
  const [finalTooltips, setFinalTooltips] = useState([])

  const currentPrice = historicalPrices?.[0]?.close || 0
  const { targetHigh, targetLow, targetConsensus } = priceTargets || {}

  // Safe calculations with null checks
  const changeFromAvg = targetConsensus
    ? (((targetConsensus - currentPrice) / targetConsensus) * 100).toFixed(2)
    : "0.00"

  const highChange = targetHigh
    ? (((targetHigh - currentPrice) / currentPrice) * 100).toFixed(2)
    : null

  const lowChange = targetLow
    ? (((targetLow - currentPrice) / currentPrice) * 100).toFixed(2)
    : null

  // Convert daily historical data to monthly (last 12 months)
  const monthlyHistorical = historicalPrices.reduce((acc, day) => {
    if (!day?.date) return acc
    const monthYear = moment(day.date).format("YYYY-MM")
    if (!acc[monthYear] || moment(day.date).date() <= 5) {
      acc[monthYear] = {
        date: day.date,
        close: day.close,
      }
    }
    return acc
  }, {})

  const chronologicalMonthlyPrices = Object.values(monthlyHistorical)
    .sort((a, b) => moment(a?.date).diff(moment(b?.date)))
    .slice(-12)

  // Helper function to safely create arrays
  const createSafeArray = (length, fillValue = null) =>
    Array.from({ length: Math.max(0, length) }).fill(fillValue)

  // Create 12-month projection points
  const createProjectionPoints = (targetValue) => {
    if (!chronologicalMonthlyPrices.length || targetValue == null) return []

    const lastPoint =
      chronologicalMonthlyPrices[chronologicalMonthlyPrices.length - 1] || {}
    const startDate = lastPoint.date ? moment(lastPoint.date) : moment()
    const startPrice = lastPoint.close || currentPrice
    const priceDifference = targetValue - startPrice

    return createSafeArray(13).map((_, i) => ({
      date: moment(startDate).add(i, "months").format("YYYY-MM-DD"),
      price: startPrice + (priceDifference * i) / 12,
    }))
  }

  const highProjection = createProjectionPoints(targetHigh)
  const avgProjection = createProjectionPoints(targetConsensus)
  const lowProjection = createProjectionPoints(targetLow)

  // Prepare chart data
  const historicalLabels = chronologicalMonthlyPrices.map((d) =>
    d?.date ? moment(d.date).format("MMM YY") : ""
  )

  const projectionLabels = highProjection
    .slice(1)
    .map((d, i, arr) =>
      i === arr.length - 1 && d?.date ? moment(d.date).format("MMM YY") : ""
    )

  const labels = [...historicalLabels, ...projectionLabels]

  const chartData = {
    labels,
    datasets: [
      {
        label: "Historical Price",
        data: chronologicalMonthlyPrices.map((d) => d?.close || null),
        borderColor: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.main,
        borderWidth: 2,
        tension: 0.1,
        pointRadius: createSafeArray(chronologicalMonthlyPrices.length, 3),
        pointHoverRadius: 5,
      },
      {
        label: "High Target (12m)",
        data: [
          ...createSafeArray(chronologicalMonthlyPrices.length - 1),
          chronologicalMonthlyPrices[chronologicalMonthlyPrices.length - 1]
            ?.close || currentPrice,
          ...highProjection.slice(1).map((d) => d?.price),
        ],
        borderColor: "#4caf50",
        backgroundColor: "#4caf50",
        borderWidth: 2,
        borderDash: [5, 3],
        tension: 0.1,
        pointRadius: [
          ...createSafeArray(chronologicalMonthlyPrices.length - 1, 0),
          0, // No point at transition
          ...highProjection
            .slice(1)
            .map((_, i, arr) => (i === arr.length - 1 ? 5 : 0)),
        ],
        pointHoverRadius: 5,
      },
      {
        label: "Avg Target (12m)",
        data: [
          ...createSafeArray(chronologicalMonthlyPrices.length - 1),
          chronologicalMonthlyPrices[chronologicalMonthlyPrices.length - 1]
            ?.close || currentPrice,
          ...avgProjection.slice(1).map((d) => d?.price),
        ],
        borderColor: "#808080",
        backgroundColor: "#808080",
        borderWidth: 2,
        borderDash: [5, 3],
        tension: 0.1,
        pointRadius: [
          ...createSafeArray(chronologicalMonthlyPrices.length - 1, 0),
          0,
          ...avgProjection
            .slice(1)
            .map((_, i, arr) => (i === arr.length - 1 ? 5 : 0)),
        ],
        pointHoverRadius: 5,
      },
      {
        label: "Low Target (12m)",
        data: [
          ...createSafeArray(chronologicalMonthlyPrices.length - 1),
          chronologicalMonthlyPrices[chronologicalMonthlyPrices.length - 1]
            ?.close || currentPrice,
          ...lowProjection.slice(1).map((d) => d?.price),
        ],
        borderColor: "#f44336",
        backgroundColor: "#f44336",
        borderWidth: 2,
        borderDash: [5, 3],
        tension: 0.1,
        pointRadius: [
          ...createSafeArray(chronologicalMonthlyPrices.length - 1, 0),
          0,
          ...lowProjection
            .slice(1)
            .map((_, i, arr) => (i === arr.length - 1 ? 5 : 0)),
        ],
        pointHoverRadius: 5,
      },
    ],
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: { display: false },
        ticks: {
          maxRotation: 45,
          minRotation: 45,
          callback: function (value, index) {
            if (
              index < chronologicalMonthlyPrices.length ||
              index === labels.length - 1
            ) {
              return labels[index]
            }
            return ""
          },
        },
        afterFit: function (scale) {
          scale.paddingRight = 100 // Add right padding for tooltips
        },
      },
      y: {
        beginAtZero: false,
        ticks: { callback: (value) => `$${value}` },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        mode: "nearest",
        intersect: false,
        displayColors: false,
        filter: (tooltipItem) => {
          const isHistorical = tooltipItem.datasetIndex === 0
          const isFinalProjection =
            tooltipItem.dataIndex === chronologicalMonthlyPrices.length + 11
          return isHistorical || isFinalProjection
        },
        callbacks: {
          label: (context) => {
            return `${context.dataset.label}: $${
              context.raw?.toFixed(2) || "N/A"
            }`
          },
        },
      },
    },
    interaction: {
      mode: "nearest",
      intersect: false,
    },
  }

  useEffect(() => {
    if (chartRef.current && chronologicalMonthlyPrices.length > 0) {
      const chart = chartRef.current
      const finalIndex = chronologicalMonthlyPrices.length + 11 // 12th projection point

      setTimeout(() => {
        const xPos = chart.scales.x.getPixelForValue(labels[finalIndex])
        const chartWidth = chart.width

        // Calculate adjusted x position to keep tooltips aligned with projections
        const adjustedXPos = Math.min(xPos, chartWidth - 120)

        const tooltips = [
          {
            label: `High $${targetHigh?.toFixed(2) || "N/A"}`,
            x: adjustedXPos,
            y: chart.scales.y.getPixelForValue(targetHigh),
            color: "#4caf50",
          },
          {
            label: `Average $${targetConsensus?.toFixed(2) || "N/A"}`,
            x: adjustedXPos,
            y: chart.scales.y.getPixelForValue(targetConsensus),
            color: "#808080",
          },
          {
            label: `Low $${targetLow?.toFixed(2) || "N/A"}`,
            x: adjustedXPos,
            y: chart.scales.y.getPixelForValue(targetLow),
            color: "#f44336",
          },
        ]
        setFinalTooltips(tooltips)
      }, 500)
    }
  }, [chartRef.current, chronologicalMonthlyPrices, priceTargets])

  return (
    <Box>
      <TableContainer
        component={Paper}
        sx={{
          width: "100%",
          overflowX: "hidden",
        }}
      >
        <Table
          aria-label="price targets table"
          sx={{
            width: "100%",
            tableLayout: "fixed",
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}>
                <div className={classes["main-header"]}>
                  {tickerId} Stock 12 Month Forecast
                </div>
                <div className={classes["sub-header"]}>
                  Based on analysts offering 12 month price targets for{" "}
                  {tickerId} in the last 3 months.
                </div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell colSpan={2} sx={{ padding: 0 }}>
                <Box>
                  {/* First section (previously first TableRow) */}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: { xs: "column", sm: "row" },
                      alignItems: { xs: "flex-start", sm: "center" },
                      gap: { xs: 1, sm: 4 },
                      py: 2,
                      px: 2, // Add horizontal padding to match TableCell default padding
                    }}
                  >
                    <Stack
                      direction="column"
                      spacing={0.5}
                      sx={{
                        minWidth: { xs: "100%", sm: "200px" },
                        alignItems: { xs: "center", sm: "flex-start" },
                      }}
                    >
                      <Typography variant="h4" fontWeight="bold" noWrap>
                        ${targetConsensus?.toFixed(2) || "N/A"}
                      </Typography>
                      {changeFromAvg && (
                        <Typography
                          variant="h6"
                          color={
                            Number(changeFromAvg) >= 0 ? "#4caf50" : "#f44336"
                          }
                          fontWeight="bold"
                          noWrap
                          sx={{
                            whiteSpace: "nowrap",
                            textAlign: { xs: "center", sm: "left" },
                          }}
                        >
                          ({changeFromAvg}%{" "}
                          {Number(changeFromAvg) >= 0 ? "Upside" : "Downside"})
                        </Typography>
                      )}
                    </Stack>

                    <Typography
                      variant="body2"
                      sx={{
                        lineHeight: 1.6,
                        flex: 1,
                        minWidth: 0,
                      }}
                    >
                      {targetConsensus ? (
                        <>
                          The average price target is $
                          {targetConsensus.toFixed(2)} with a high forecast of $
                          {targetHigh?.toFixed(2) || "N/A"} and a low forecast
                          of ${targetLow?.toFixed(2) || "N/A"}. The average
                          price target represents a {changeFromAvg}% change from
                          the last price of ${currentPrice.toFixed(2)}.
                        </>
                      ) : (
                        "No price targets available from analysts."
                      )}
                    </Typography>
                  </Box>

                  {/* Second section (previously second TableRow) */}
                  <Box
                    sx={{
                      position: "relative",
                      padding: 0,
                      overflow: "visible",
                    }}
                  >
                    <Box
                      height={400}
                      sx={{
                        width: "100%",
                        position: "relative",
                        //height: (theme) => `calc(${theme.spacing(50)} * 1)`, // 25% increase
                        "& canvas": {
                          width: "100%!important",
                          height: "100%!important",
                        },
                      }}
                    >
                      {chronologicalMonthlyPrices.length > 0 ? (
                        <>
                          <Chart
                            ref={chartRef}
                            type="line"
                            data={chartData}
                            options={options}
                          />
                          {finalTooltips.map((tooltip, index) => (
                            <Box
                              key={index}
                              sx={{
                                position: "absolute",
                                left: `${tooltip.x}px`,
                                top: `${tooltip.y}px`,
                                transform: "translate(-30%, -140%)", // Centered above marker
                                bgcolor: "background.paper",
                                borderLeft: `4px solid ${tooltip.color}`,
                                boxShadow: 1,
                                p: 0.5,
                                borderRadius: 1,
                                pointerEvents: "none",
                                zIndex: 10,
                                wordWrap: "break-word",
                                maxWidth: { xs: "120px", sm: "150px" },
                                fontSize: { xs: "0.65rem", sm: "0.7rem" },
                                "&:before": {
                                  // Optional arrow pointer
                                  content: '""',
                                  position: "absolute",
                                  bottom: "-5px",
                                  left: "50%",
                                  transform: "translateX(-50%)",
                                  borderWidth: "5px 5px 0",
                                  borderStyle: "solid",
                                  borderColor: `${tooltip.color} transparent transparent`,
                                },
                              }}
                            >
                              <Typography variant="caption">
                                {tooltip.label}
                              </Typography>
                            </Box>
                          ))}
                        </>
                      ) : (
                        <Typography>No data available for chart.</Typography>
                      )}
                    </Box>
                  </Box>
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

PriceTargets.propTypes = {
  priceTargets: PropTypes.object,
  historicalPrices: PropTypes.array,
}

PriceTargets.defaultProps = {
  priceTargets: {},
  historicalPrices: [],
}

export default PriceTargets

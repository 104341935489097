import { Navigate } from "react-router-dom"
import { useLocation } from "react-router-dom"
import { toast } from "react-toastify"

const ProtectedRoute = ({ children }) => {
  const { pathname } = useLocation()

  try {
    const isAuthenticated =
      window.sessionStorage.getItem("tokenId") &&
      JSON.parse(window.sessionStorage.getItem("currentUser"))

    if (
      !isAuthenticated &&
      !(
        pathname.includes("calculators") ||
        pathname.includes("guides") ||
        pathname.includes("reading") ||
        pathname.includes("indexes")
      )
    ) {
      // Store intended URL
      window.sessionStorage.setItem("intendedUrl", pathname)
      setTimeout(() => {
        toast.warn("Please login to continue.")
      })
      return <Navigate to="/" replace />
    }
    return children
  } catch (err) {
    // Store intended URL
    window.sessionStorage.setItem("intendedUrl", pathname)
    setTimeout(() => {
      toast.warn("Please login to continue.")
    })
    return <Navigate to="/" replace />
  }
}

export default ProtectedRoute

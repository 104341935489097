import React from "react"
import { Slider } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { displayValue } from "../../utils/NumberFormatters"

const useStyles = makeStyles((theme) => ({
  rangeContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(1),
    marginLeft: "15px",
    marginRight: "15px",
  },
  sliderTrack: {
    backgroundColor: theme.palette.primary.main, // Customize track color (optional)
  },
  sliderMarkLabel: {
    fontSize: "0.65rem !important", // Adjust font size for marks and value label
    marginTop: "-10px !important",
  },
  thumb: {
    backgroundColor: "#fff",
  },
}))

const StockPriceRange = ({
  fiftyTwoWeekLow,
  fiftyTwoWeekHigh,
  currentPrice,
}) => {
  const classes = useStyles()
  const marks = [
    {
      value: fiftyTwoWeekLow,
      label: displayValue(fiftyTwoWeekLow, "$", true, false, true, false), // Display with $ sign
    },
    {
      value: fiftyTwoWeekHigh,
      label: displayValue(fiftyTwoWeekHigh, "$", true, false, true, false), // Display with $ sign
    },
  ]

  return (
    <div className={classes.rangeContainer}>
      <Slider
        value={currentPrice}
        min={fiftyTwoWeekLow}
        max={fiftyTwoWeekHigh}
        size="small"
        marks={marks}
        disabled // Disable interaction to show current price only
        classes={{
          track: classes.sliderTrack,
          thumb: classes.thumb,
          markLabel: classes.sliderMarkLabel,
        }} // Optional styling
        valueLabelDisplay="on"
        valueLabelFormat={(value) =>
          displayValue(value, "$", true, false, true, false)
        } // Format value label with $ sign
      />
    </div>
  )
}

export default StockPriceRange

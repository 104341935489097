import React from "react"
import {
  Box,
  Typography,
  Input,
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material"
import PropTypes from "prop-types"
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline"

import useStyles from "../styles"
import {
  InputThousandSeparator,
  displayValue,
} from "../../../utils/NumberFormatters"
import Term from "../../../utils/Term"

const Calculator = ({
  calculatorParams,
  setCalculatorParams,
  frequency,
  errMsg,
}) => {
  const classes = useStyles()

  return (
    <Box>
      <Grid container direction="row">
        <Grid item xs={12} className={classes["calculator-header-text"]}>
          <Typography>
            <b>Compound Interest Calculator</b>
          </Typography>
        </Grid>
        {/* Initial investment */}
        <Grid item xs={7} md={5}>
          <Typography className={classes.labels}>
            <Box display={"flex"} alignItems="center">
              Initial Investment
              <span className="ml1 mt1">
                <Term
                  config={{
                    key: "initial_investment",
                    type: "Compound Interest Calculator",
                  }}
                />
              </span>
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={5} md={3}>
          <Input
            value={
              calculatorParams.initialVal === 0
                ? ""
                : calculatorParams.initialVal.toString()
            }
            inputComponent={InputThousandSeparator}
            inputProps={{
              style: { textAlign: "center" },
              prefix: calculatorParams.initialVal !== 0 ? "$" : "",
              onChange: (event) => {
                // Get raw value without formatting
                const rawValue = event.target.value

                // Convert to number (empty string becomes 0)
                const numericValue = rawValue === "" ? 0 : Number(rawValue)

                setCalculatorParams({
                  ...calculatorParams,
                  initialVal: numericValue,
                })
              },
              onBlur: () => {
                // Ensure we show the prefix when field has a value
                if (calculatorParams.initialVal > 0) {
                  setCalculatorParams({
                    ...calculatorParams,
                    initialVal: calculatorParams.initialVal, // Force re-render
                  })
                }
              },
              thousandSeparator: true,
            }}
          />
        </Grid>
        <Grid item xs={0} md={4} />
        {/* Contributions */}
        <Grid item xs={7} md={5}>
          <Typography className={classes.labels}>
            <Box display={"flex"} alignItems="center">
              Contributions
              <span className="ml1 mt1">
                <Term
                  config={{
                    key: "contributions",
                    type: "Compound Interest Calculator",
                  }}
                />
              </span>
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={5} md={3}>
          <Input
            value={
              calculatorParams.contribution === 0
                ? ""
                : calculatorParams.contribution.toString()
            }
            inputComponent={InputThousandSeparator}
            inputProps={{
              style: { textAlign: "center" },
              prefix: calculatorParams.contribution !== 0 ? "$" : "",
              onChange: (event) => {
                // Get raw value without formatting
                const rawValue = event.target.value

                // Convert to number (empty string becomes 0)
                const numericValue = rawValue === "" ? 0 : Number(rawValue)

                setCalculatorParams({
                  ...calculatorParams,
                  contribution: numericValue,
                })
              },
              onBlur: () => {
                // Ensure we show the prefix when field has a value
                if (calculatorParams.contribution > 0) {
                  setCalculatorParams({
                    ...calculatorParams,
                    contribution: calculatorParams.contribution, // Force re-render
                  })
                }
              },
              thousandSeparator: true,
            }}
          />
        </Grid>
        <Grid item xs={0} md={4} />
        {/* Contributions frequency */}
        <Grid item xs={7} md={4}>
          <Typography className={classes.labels}></Typography>
        </Grid>
        <Grid item xs={5} md={5} className={classes["contribution-type"]}>
          <RadioGroup
            row
            value={calculatorParams.contributionType}
            onChange={(event) =>
              setCalculatorParams({
                ...calculatorParams,
                contributionType: event.target.value,
              })
            }
          >
            <FormControlLabel value="M" control={<Radio />} label="Monthly" />
            <FormControlLabel value="Y" control={<Radio />} label="Annually" />
          </RadioGroup>
        </Grid>
        <Grid item xs={0} md={3} />
        {/* Number of years to investment */}
        <Grid item xs={7} md={5}>
          <Typography className={classes.labels}>
            <Box display={"flex"} alignItems="center">
              Number of years you want to invest for
              <span className="ml1 mt1">
                <Term
                  config={{
                    key: "investment_duration",
                    type: "Compound Interest Calculator",
                  }}
                />
              </span>
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={5} md={3}>
          <Input
            value={
              calculatorParams.noOfYears === 0
                ? ""
                : calculatorParams.noOfYears.toString()
            }
            type="number"
            inputProps={{
              style: { textAlign: "center" },
              min: "0", // Ensures only positive numbers
              pattern: "\\d*", // Forces numeric keyboard on mobile
            }}
            onKeyPress={(event) => {
              if (event.key === "." || event.key === "e" || event.key === "-") {
                event.preventDefault() // Disallow decimal, exponential, and negative
              }
            }}
            onChange={(event) => {
              const rawValue = event.target.value
              // Only update if value is empty or a valid positive integer
              if (rawValue === "" || /^\d+$/.test(rawValue)) {
                setCalculatorParams({
                  ...calculatorParams,
                  noOfYears: rawValue === "" ? 0 : Math.floor(Number(rawValue)),
                })
              }
            }}
            onBlur={() => {
              // Ensure we don't have empty state after blur
              if (calculatorParams.noOfYears === 0) {
                setCalculatorParams({
                  ...calculatorParams,
                  noOfYears: 0, // Forces re-render to show empty field
                })
              }
            }}
          />
        </Grid>
        <Grid item xs={0} md={4} />
        {/* Number of years to stay invested */}
        <Grid item xs={7} md={5}>
          <Typography className={classes.labels}>
            <Box display={"flex"} alignItems="center">
              Number of years you want to stay invested for
              <span className="ml1 mt1">
                <Term
                  config={{
                    key: "no_of_years_invested_for",
                    type: "Compound Interest Calculator",
                  }}
                />
              </span>
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={5} md={3}>
          <Input
            value={
              calculatorParams.noOfYearsInvested === 0
                ? ""
                : calculatorParams.noOfYearsInvested.toString()
            }
            type="number"
            inputProps={{
              style: { textAlign: "center" },
              min: "0", // Ensures only positive numbers
              pattern: "\\d*", // Forces numeric keyboard on mobile
            }}
            onKeyPress={(event) => {
              if (event.key === "." || event.key === "e" || event.key === "-") {
                event.preventDefault() // Disallow decimal, exponential, and negative
              }
            }}
            onChange={(event) => {
              const rawValue = event.target.value
              // Only update if value is empty or a valid positive integer
              if (rawValue === "" || /^\d+$/.test(rawValue)) {
                setCalculatorParams({
                  ...calculatorParams,
                  noOfYearsInvested:
                    rawValue === "" ? 0 : Math.floor(Number(rawValue)),
                })
              }
            }}
            onBlur={() => {
              // Ensure we don't have empty state after blur
              if (calculatorParams.noOfYearsInvested === 0) {
                setCalculatorParams({
                  ...calculatorParams,
                  noOfYearsInvested: 0, // Forces re-render to show empty field
                })
              }
            }}
          />
        </Grid>
        <Grid item xs={0} md={4} />
        {/* Estimated Rate of Return */}
        <Grid item xs={7} md={5}>
          <Typography className={classes.labels}>
            <Box display={"flex"} alignItems="center">
              Estimated Rate of Return
              <span className="ml1 mt1">
                <Term
                  config={{
                    key: "estimated_rate_of_return",
                    type: "Compound Interest Calculator",
                  }}
                />
              </span>
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={5} md={3}>
          <Input
            value={
              calculatorParams.rateOfReturn === 0
                ? ""
                : calculatorParams.rateOfReturn.toString()
            }
            inputComponent={InputThousandSeparator}
            inputProps={{
              style: { textAlign: "center" },
              suffix: calculatorParams.rateOfReturn !== 0 ? "%" : "",
              allowNegative: false,
              onChange: (event) => {
                // Get raw value without formatting
                const rawValue = event.target.value

                // Convert to number (empty string becomes 0)
                const numericValue = rawValue === "" ? 0 : Number(rawValue)

                setCalculatorParams({
                  ...calculatorParams,
                  rateOfReturn: numericValue,
                })
              },
              onBlur: () => {
                // Ensure we show the suffix when field has a value
                if (calculatorParams.rateOfReturn > 0) {
                  setCalculatorParams({
                    ...calculatorParams,
                    rateOfReturn: calculatorParams.rateOfReturn, // Force re-render
                  })
                }
              },
              thousandSeparator: true,
            }}
          />
        </Grid>
        <Grid item xs={0} md={4} />
        {/* Compounding Frequency */}
        <Grid item xs={7} md={5}>
          <Typography className={classes.labels}>
            <Box display={"flex"} alignItems="center">
              Compounding Frequency
              <span className="ml1 mt1">
                <Term
                  config={{
                    key: "compounding_frequency",
                    type: "Compound Interest Calculator",
                  }}
                />
              </span>
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={5} md={5}>
          <RadioGroup
            row
            value={calculatorParams.compoundingFreq}
            onChange={(event) =>
              setCalculatorParams({
                ...calculatorParams,
                compoundingFreq: event.target.value,
              })
            }
          >
            <FormControlLabel value="D" control={<Radio />} label="Daily" />
            <FormControlLabel value="M" control={<Radio />} label="Monthly" />
            <FormControlLabel value="Y" control={<Radio />} label="Annually" />
          </RadioGroup>
        </Grid>
        <Grid item xs={0} md={2}></Grid>
        {/* Rate Variance */}
        <Grid item xs={7} md={5}>
          <Typography className={classes.labels}>
            <Box display={"flex"} alignItems="center">
              Rate Variance
              <span className="ml1 mt1">
                <Term
                  config={{
                    key: "rate_variance",
                    type: "Compound Interest Calculator",
                  }}
                />
              </span>
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={5} md={3}>
          <Input
            value={
              calculatorParams.rateVar === 0
                ? ""
                : calculatorParams.rateVar.toString()
            }
            inputComponent={InputThousandSeparator}
            inputProps={{
              style: { textAlign: "center" },
              suffix: calculatorParams.rateVar !== 0 ? "%" : "",
              allowNegative: false,
              onChange: (event) => {
                // Get raw value without formatting
                const rawValue = event.target.value

                // Convert to number (empty string becomes 0)
                const numericValue = rawValue === "" ? 0 : Number(rawValue)

                setCalculatorParams({
                  ...calculatorParams,
                  rateVar: numericValue,
                })
              },
              onBlur: () => {
                // Ensure we show the suffix when field has a value
                if (calculatorParams.rateVar > 0) {
                  setCalculatorParams({
                    ...calculatorParams,
                    rateVar: calculatorParams.rateVar, // Force re-render
                  })
                }
              },
              thousandSeparator: true,
            }}
          />
        </Grid>
        <Grid item xs={0} md={4} />
        {/* Modified error display - more prominent */}
        {/* Modified error display - more prominent */}
        {errMsg && (
          <Grid item xs={12} sx={{ mt: 2, mb: 2 }}>
            <Typography
              sx={{
                color: "white",
                fontWeight: "bold",
                fontSize: "0.9rem",
                bgcolor: "error.light",
                p: 1,
                borderRadius: 1,
                display: "flex",
                alignItems: "center",
              }}
            >
              <ErrorOutlineIcon fontSize="small" sx={{ mr: 1 }} />
              {errMsg}
            </Typography>
          </Grid>
        )}
        {/* Compounded value */}
        <Grid item xs={12}>
          <Typography className={classes["compound-calc-text"]}>
            Starting with{" "}
            <b>
              {displayValue(
                calculatorParams.initialVal,
                "$",
                true,
                false,
                false,
                false
              )}
            </b>{" "}
            and contributing{" "}
            <b>
              {displayValue(
                calculatorParams.contribution,
                "$",
                true,
                false,
                false,
                false
              )}
            </b>{" "}
            <b>{frequency[calculatorParams.contributionType]}</b>, you would
            make a total contribution of{" "}
            <b>
              {displayValue(
                calculatorParams.total,
                "$",
                true,
                false,
                false,
                false
              )}
            </b>{" "}
            over <b>{calculatorParams.noOfYears}</b> years. Your investment
            would grow to the following amounts at various annual interest rates
            if you compounded{" "}
            <b>{frequency[calculatorParams.compoundingFreq]}</b> and remained
            invested for <b>{calculatorParams.noOfYearsInvested}</b> years:
          </Typography>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Calculator

Calculator.propTypes = {
  calculatorParams: PropTypes.object.isRequired,
  setCalculatorParams: PropTypes.func.isRequired,
}

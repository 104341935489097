import { makeStyles } from "@mui/styles"
import { palette } from "../../../utils/Palette"

export default makeStyles((theme) => ({
  header: {
    fontWeight: "500 !important",
    padding: "12px 0 !important",
  },
  "main-header": {
    fontSize: "18px !important",
  },
  "sub-header": {
    color: theme.palette["grey-2"],
    fontSize: "14px",
  },
  "content-txt": {
    color: theme.palette["grey-2"],
    textAlign: "justify",
  },
  "recommend-div": {
    color: theme.palette.purple,
  },
  "range-line": {
    backgroundColor: theme.palette["grey-3"],
    height: "2px",
    width: "100%",
  },
  "price-box": {
    display: "flex",
    flexDirection: "column",
  },
  "price-circle": {
    width: "12px !important",
    height: "12px !important",
    marginTop: "-7px",
  },
  "price-current": {
    marginTop: "-4px",
  },
  "success-dark": {
    color: palette["success-dark"],
  },
  "error-dark": {
    color: palette["error-dark"],
  },
  "warning-dark": {
    color: palette["grey-2"],
  },
  purple: {
    color: palette["purple"],
  },
  alert: {
    marginBottom: "10px",
    "& .MuiAlert-icon": {
      alignItems: "center",
    },
  },
  title: {
    color: `${theme.palette["news"]} !important`,
    display: "block",
    fontSize: "14px !important",
    textDecoration: "none !important",
    paddingLeft: 8,
    whiteSpace: "pre-line !important",
    justifyContent: "left",
  },
  site: {
    fontSize: "14px !important",
    color: theme.palette["grey-2"],
    paddingLeft: 8,
    fontWeight: "500",
    justifyContent: "left",
  },
}))

import React, { useEffect, useState } from "react"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js"
import { Bar } from "react-chartjs-2"
import { Box, useMediaQuery, useTheme } from "@mui/material"
import PropTypes from "prop-types"
import { defaults } from "chart.js"

import { palette } from "../../../../utils/Palette"
import { yaxisLabel, thousandSeparator } from "../../../../utils/charts"

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)
defaults.font.family = palette.fontFamily

const Barchart = ({ values }) => {
  const [chartData, setChartData] = useState(null)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "lg"))

  // Color scheme matching line chart exactly
  const barColors = {
    principal: {
      base: theme.palette.primary.main,
      hover: theme.palette.primary.dark,
      border: theme.palette.primary.light,
    },
    interest: {
      base: theme.palette.success.main,
      hover: theme.palette.success.dark,
      border: theme.palette.success.light,
    },
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 0, // Disable animations for instant updates
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
          drawBorder: true,
          color: theme.palette.divider,
        },
        ticks: {
          font: {
            size: isMobile ? 10 : 12,
            family: theme.typography.fontFamily,
          },
          color: theme.palette.text.secondary,
          padding: 15,
          autoSkip: false,
        },
      },
      y: {
        stacked: true,
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          font: {
            size: isMobile ? 10 : 12,
            family: theme.typography.fontFamily,
          },
          color: theme.palette.text.secondary,
          padding: 10,
          callback: yaxisLabel,
        },
      },
    },
    interaction: {
      mode: "x",
      intersect: false,
    },
    plugins: {
      tooltip: {
        backgroundColor: theme.palette.background.paper,
        titleColor: theme.palette.text.primary,
        bodyColor: theme.palette.text.secondary,
        borderColor: theme.palette.divider,
        borderWidth: 1,
        padding: 12,
        displayColors: true,
        usePointStyle: true,
        boxPadding: 6,
        callbacks: {
          label: function (context) {
            const label = context.dataset.label || ""
            const value = context.formattedValue || ""
            return ` ${label}: $${value}`
          },
          afterBody: (tooltipItems) => {
            if (!tooltipItems.length) return []
            const principal = tooltipItems[0].parsed.y
            const interest = tooltipItems[1]?.parsed.y || 0
            const total = principal + interest
            return [`Total: $${thousandSeparator(total)}`]
          },
          labelColor: function (context) {
            return {
              borderColor: "transparent",
              backgroundColor:
                context.datasetIndex === 0
                  ? barColors.principal.base
                  : barColors.interest.base,
              borderRadius: 4,
            }
          },
        },
      },
      legend: {
        position: "top",
        labels: {
          padding: 20,
          font: {
            size: isMobile ? 12 : 14,
            family: theme.typography.fontFamily,
          },
          color: theme.palette.text.primary,
          boxWidth: 12,
          usePointStyle: true,
        },
      },
      title: {
        display: true,
        text: `Future Balance $${thousandSeparator(
          values.slice(-1)[0].futureVal
        )}`,
        font: {
          size: isMobile ? 14 : 16,
          weight: "bold",
          family: theme.typography.fontFamily,
        },
        color: theme.palette.text.primary,
        padding: {
          top: 0,
          bottom: 20,
        },
      },
    },
    barThickness: isMobile ? "flex" : 30,
    maxBarThickness: 40,
    categoryPercentage: 0.85,
    barPercentage: 0.8,
    grouped: true,
  }

  useEffect(() => {
    if (!values || values.length === 0) return

    const newChartData = {
      labels: values.map((val) => val.year),
      datasets: [
        {
          label: "Principal",
          data: values.map((val) => parseInt(val.total)),
          backgroundColor: barColors.principal.base,
          hoverBackgroundColor: barColors.principal.hover,
          borderColor: barColors.principal.border,
          borderWidth: 1,
          borderRadius: 0,
          hoverBorderWidth: 2,
        },
        {
          label: "Interest Earned",
          data: values.map(
            (val) => parseInt(val.futureVal) - parseInt(val.total)
          ),
          backgroundColor: barColors.interest.base,
          hoverBackgroundColor: barColors.interest.hover,
          borderColor: barColors.interest.border,
          borderWidth: 1,
          borderRadius: {
            topLeft: 6,
            topRight: 6,
            bottomLeft: 0,
            bottomRight: 0,
          },
          hoverBorderWidth: 2,
        },
      ],
    }

    setChartData(newChartData)
  }, [values]) // Removed JSON.stringify dependency

  return (
    <Box
      sx={{
        width: "100%",
        height: isMobile ? "380px" : "420px",
        position: "relative",
        marginBottom: isTablet ? "60px" : "40px",
      }}
    >
      {chartData && (
        <Bar
          key={JSON.stringify(values)} // Force re-render on data change
          options={options}
          data={chartData}
        />
      )}
    </Box>
  )
}

export default Barchart

Barchart.propTypes = {
  values: PropTypes.array.isRequired,
}

import React, { useState, useContext, Fragment } from "react"
import { useNavigate } from "react-router-dom"
import { Autocomplete, Box, TextField, CircularProgress } from "@mui/material"
import { toast } from "react-toastify"
import { debounce } from "lodash"

import useStyles from "./styles"
import { searchTicker } from "../../services/core"
import AppDataContext from "../../context/data"
import ReactGA from "react-ga4"

const Search = () => {
  const classes = useStyles()
  const navigate = useNavigate()

  const [tickers, setTickers] = useState([])
  const [loading, setLoading] = useState(false)
  const [inputVal, setInputVal] = useState("")
  const {
    recentSearches,
    setRecentSearches,
    setOverview,
    setProfile,
    setBalanceSheet,
    setCashFlowStatment,
    setCheckList,
    setIncomeStatment,
    setKeyFinancials,
    setKeyInsights,
    setRatios,
    setValuationRef,
    setSecFilings,
    setShareholders,
    setInsiderTransactions,
    setDividends,
    setAnalysts,
    setPeers,
    setRevenueSegments,
    setEarningsCallTranscripts,
    setFingraphs,
    setProsAndCons,
  } = useContext(AppDataContext)

  const searchTickers = async (searchTxt) => {
    toast.dismiss()
    setLoading(true)
    try {
      setTickers([])
      const tickersResp = await searchTicker(
        `query=${searchTxt}&limit=10&exchange=NASDAQ`
      )
      setTickers(tickersResp.data)
      setLoading(false)
    } catch (err) {
      toast.error("Unable to search for tickers. Please try after sometime.")
      setLoading(false)
    }
  }

  const debounceSearchTicker = debounce(searchTickers, 500, { maxWait: 1000 })

  return (
    <Box width="100%">
      <Autocomplete
        className={classes["search-box"]}
        freeSolo
        options={tickers || []}
        openOnFocus={true}
        popoverprops={{
          style: {
            bottom: 0,
            overflowY: "auto",
          },
        }}
        loading={loading}
        clearOnEscape={true}
        blurOnSelect={true}
        inputValue={inputVal}
        getOptionLabel={(option) => `${option?.symbol} - ${option?.name}`}
        /* renderOption={(props, option) => (
          <Box
            component="li"
            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
            {...props}
          >
            <img
              loading="lazy"
              width="20"
              src={`https://financialmodelingprep.com/image-stock/${option.symbol}.png`}
              alt=""
            />
            {option?.symbol} - {option?.name}
          </Box>
        )} */
        onInputChange={(event, ticker, reason) => {
          if (reason === "clear") setTickers([])
        }}
        onChange={(event, ticker) => {
          if (
            event.key === "Enter" &&
            tickers[0].symbol === ticker.toUpperCase()
          ) {
            const symbol = ticker
            ticker = {}
            ticker.symbol = symbol.toUpperCase()
          }
          if (ticker?.symbol) {
            if (recentSearches)
              setRecentSearches(
                Array.from(new Set([...recentSearches, ticker.symbol]))
              )
            else setRecentSearches([ticker.symbol])
            setOverview(null)
            setProfile(null)
            setBalanceSheet(null)
            setCashFlowStatment(null)
            setCheckList(null)
            setIncomeStatment(null)
            setKeyFinancials(null)
            setKeyInsights(null)
            setRatios(null)
            setInputVal("")
            setTickers([])
            setValuationRef(null)
            setSecFilings(null)
            setShareholders(null)
            setInsiderTransactions(null)
            setDividends(null)
            setAnalysts(null)
            setPeers(null)
            setRevenueSegments(null)
            setEarningsCallTranscripts(null)
            setFingraphs(null)
            setProsAndCons(null)
            // Send a custom event
            ReactGA.event({
              category: "Ticker Search",
              action: "ticker_search",
              label: ticker.symbol, // optional
            })
            const pathname = window.location.pathname
            if (pathname.indexOf("ticker") === -1) {
              // direct to overview page
              navigate(`/ticker/${ticker.symbol}/overview`)
            } else {
              // make sure same page for the new ticker is displayed
              const previousTicker = pathname.split("/")[2]
              const newPathName = pathname.replace(
                previousTicker,
                ticker.symbol
              )
              navigate(newPathName)
            }
          } else {
            if (event.key === "Enter") {
              event.preventDefault()
              toast.warn("Please select from the suggested options")
            }
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="&#x1F50E;   Search company name or ticker..."
            label=""
            size="small"
            onChange={(event) => {
              setInputVal(event.target.value)
              setLoading(true)
              event.target.value && debounceSearchTicker(event.target.value)
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <Fragment>
                  {loading ? (
                    <CircularProgress
                      color="inherit"
                      className={classes.loader}
                      size={20}
                    />
                  ) : null}
                  {params.InputProps.endAdornment}
                </Fragment>
              ),
            }}
          />
        )}
        ListboxProps={{
          style: {
            maxHeight: "200px",
          },
        }}
      />
    </Box>
  )
}

export default Search

import React, { useState, useEffect, useContext } from "react"
import {
  Button,
  Box,
  Typography,
  Grid,
  Alert,
  FormControl,
  Checkbox,
  FormControlLabel,
  Tabs,
  Tab,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Tooltip,
} from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"
import SaveIcon from "@mui/icons-material/Save"
import Select from "react-select"
import AddIcon from "@mui/icons-material/Add"
import { toast } from "react-toastify"
import Filter from "./filter"
import { findStocks } from "../../services/core"
import AppDataContext from "../../context/data"
import useStyles from "./styles"
import FilteredStocks from "./filteredStocks"
import Loading from "../Loading"
import { allClassifications, allSectors } from "./selectOptions.js"
import {
  createScreener,
  updateScreener,
  getAllScreeners,
  deleteScreener,
} from "../../services/user.js"

function Screener() {
  const classes = useStyles()
  const {
    defaultScreenerData,
    setDefaultScreenerData,
    userScreenersData,
    setUserScreenersData,
    screenerData,
    setScreenerData,
  } = useContext(AppDataContext)
  const [userScreeners, setUserScreeners] = useState([])
  const [selectedTab, setSelectedTab] = useState(0)
  const userId = window.sessionStorage.getItem("userId")
  const [filteredStocks, setFilteredStocks] = useState([])
  const [displayResults, setDisplayResults] = useState(false)
  const [displayFindStocksButton, setDisplayFindStocksButton] = useState(false)
  const [filterCriteriaChanged, setFilterCriteriaChanged] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [renameDialogOpen, setRenameDialogOpen] = useState(false)
  const [renameInputValue, setRenameInputValue] = useState("")
  const [nameLengthError, setNameLengthError] = useState("") // Added error state
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [screenerIndexToDelete, setScreenerIndexToDelete] = useState(null)

  useEffect(() => {
    const fetchScreeners = async () => {
      try {
        const response = await getAllScreeners(userId)
        setUserScreeners([
          { name: "Default", data: defaultScreenerData },
          ...response.data,
        ])
        setUserScreenersData(response.data)
        if (selectedTab !== 0 && response.data.length > 0) {
          setScreenerData({ ...response.data[selectedTab - 1].data })
        } else if (selectedTab === 0) {
          setScreenerData(defaultScreenerData)
        }
      } catch (error) {
        console.error("error fetching screeners", error)
      }
    }
    if (userId) {
      fetchScreeners()
    }
  }, [userId])

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue)
    setDisplayResults(false)
    setDisplayFindStocksButton(false)
    if (newValue === 0) {
      setScreenerData(defaultScreenerData)
    } else {
      setScreenerData({ ...userScreenersData[newValue - 1].data })
    }
  }

  const handleSaveScreener = async (index) => {
    try {
      setNameLengthError("")
      if (index === 0) {
        setRenameInputValue("")
        setRenameDialogOpen(true)
      } else {
        // Use userScreenersData directly to get the correct name
        setRenameInputValue(userScreenersData[index - 1].name)
        setRenameDialogOpen(true)
      }
    } catch (error) {
      toast.error("Failed to save screener")
    }
  }

  const handleRenameDialogClose = () => {
    setRenameDialogOpen(false)
  }

  const handleRenameDialogConfirm = async () => {
    if (renameInputValue.length > 50) {
      setNameLengthError("Screener name must be 50 characters or less.")
      return // Prevent creation
    }
    try {
      let newScreener

      if (selectedTab === 0) {
        // Create a new screener
        newScreener = await createScreener(userId, {
          name: renameInputValue,
          data: defaultScreenerData,
        })

        toast.success(
          "Screener " + newScreener.data.name + " saved successfully"
        )
      } else {
        // Rename an existing screener
        let updatedScreeners = [...userScreenersData]
        updatedScreeners[selectedTab - 1].name = renameInputValue
        setUserScreenersData(updatedScreeners)

        await updateScreener(
          userId,
          updatedScreeners[selectedTab - 1]._id,
          updatedScreeners[selectedTab - 1]
        )
        toast.success("Screener updated successfully")
      }

      // Fetch updated screeners
      const response = await getAllScreeners(userId)
      const updatedUserScreeners = [
        {
          name: "Default",
          data: {
            selectedSectors: [],
            excludeSelectedSectors: false,
            selectedClassifications: [],
            excludeSelectedMarketCaps: false,
            filters: [],
          },
        },
        ...response.data,
      ]
      setUserScreeners(updatedUserScreeners)
      setUserScreenersData(response.data)

      // Reset defaultScreenerData in context
      setDefaultScreenerData({
        selectedSectors: [],
        excludeSelectedSectors: false,
        selectedClassifications: [],
        excludeSelectedMarketCaps: false,
        filters: [],
      })

      // Set selected tab
      if (selectedTab === 0) {
        setSelectedTab(updatedUserScreeners.length - 1) // Select the last tab (newly created)
      }

      setRenameDialogOpen(false)
    } catch (error) {
      console.error("Error saving/renaming screener:", error)
      toast.error("Failed to save/rename screener")
      setRenameDialogOpen(false)
    }
  }

  const handleDeleteScreener = (index) => {
    if (index !== 0) {
      setScreenerIndexToDelete(index)
      setDeleteDialogOpen(true)
    }
  }

  const handleConfirmDelete = async () => {
    try {
      const screenerId = userScreenersData[screenerIndexToDelete - 1]._id
      await deleteScreener(userId, screenerId)

      let updatedScreeners = [...userScreenersData]
      updatedScreeners.splice(screenerIndexToDelete - 1, 1)
      setUserScreenersData(updatedScreeners)
      setUserScreeners([
        { name: "Default", data: defaultScreenerData },
        ...updatedScreeners,
      ])
      setSelectedTab(0)
      setScreenerData(defaultScreenerData)
      toast.success("Screener deleted successfully")
    } catch (error) {
      console.error("Error deleting screener:", error)
      toast.error("Failed to delete screener")
    } finally {
      setDeleteDialogOpen(false)
      setScreenerIndexToDelete(null)
    }
  }

  const handleCancelDelete = () => {
    setDeleteDialogOpen(false)
    setScreenerIndexToDelete(null)
  }

  const renderTabs = () => {
    return (
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        variant="scrollable"
        scrollButtons="auto"
      >
        {userScreeners.map((screener, index) => (
          <Tab key={index} label={screener.name} />
        ))}
      </Tabs>
    )
  }

  const handleAddFilter = () => {
    const currentScreenerData =
      selectedTab === 0
        ? defaultScreenerData
        : userScreenersData[selectedTab - 1].data
    const updatedScreenerData = {
      ...currentScreenerData,
      filters: [
        ...currentScreenerData.filters,
        {
          metric: {
            label: "Choose a metric...",
            value: "",
            suffix: "",
            type: "",
          },
          operator: { label: "Select operator...", value: "" },
          input: { label: "Select a value...", value: "" },
        },
      ],
    }

    if (selectedTab === 0) {
      setDefaultScreenerData(updatedScreenerData)
      setScreenerData(updatedScreenerData)
    } else {
      const updatedUserScreeners = [...userScreenersData]
      updatedUserScreeners[selectedTab - 1].data = updatedScreenerData
      setUserScreenersData(updatedUserScreeners)
      setScreenerData(updatedScreenerData)
    }

    setDisplayResults(false)
    if (filteredStocks?.length > 0) setFilterCriteriaChanged(true)
  }

  const handleFilterChange = (index, field, value) => {
    const currentScreenerData =
      selectedTab === 0
        ? defaultScreenerData
        : userScreenersData[selectedTab - 1].data
    const updatedFilters = [...currentScreenerData.filters]
    updatedFilters[index][field] = value
    const updatedScreenerData = {
      ...currentScreenerData,
      filters: updatedFilters,
    }

    if (selectedTab === 0) {
      setDefaultScreenerData(updatedScreenerData)
      setScreenerData(updatedScreenerData)
    } else {
      const updatedUserScreeners = [...userScreenersData]
      updatedUserScreeners[selectedTab - 1].data = updatedScreenerData
      setUserScreenersData(updatedUserScreeners)
      setScreenerData(updatedScreenerData)
    }

    setDisplayResults(false)
    if (filteredStocks?.length > 0) setFilterCriteriaChanged(true)
  }

  const handleDeleteFilter = (index) => {
    const currentScreenerData =
      selectedTab === 0
        ? defaultScreenerData
        : userScreenersData[selectedTab - 1].data
    const updatedFilters = [...currentScreenerData.filters]
    updatedFilters.splice(index, 1)
    const updatedScreenerData = {
      ...currentScreenerData,
      filters: updatedFilters,
    }

    if (selectedTab === 0) {
      setDefaultScreenerData(updatedScreenerData)
      setScreenerData(updatedScreenerData)
    } else {
      const updatedUserScreeners = [...userScreenersData]
      updatedUserScreeners[selectedTab - 1].data = updatedScreenerData
      setUserScreenersData(updatedUserScreeners)
      setScreenerData(updatedScreenerData)
    }

    setDisplayResults(false)
    if (filteredStocks?.length > 0) setFilterCriteriaChanged(true)
  }

  const handleFindStocks = async () => {
    setIsLoading(true)
    try {
      const currentScreenerData =
        selectedTab === 0
          ? defaultScreenerData
          : userScreenersData[selectedTab - 1].data
      const response = await findStocks(
        JSON.stringify({
          sectors: currentScreenerData.selectedSectors,
          excludeSelectedSectors: currentScreenerData.excludeSelectedSectors,
          classifications: currentScreenerData.selectedClassifications,
          excludeSelectedMarketCaps:
            currentScreenerData.excludeSelectedMarketCaps,
          filters: currentScreenerData.filters,
        })
      )
      setFilteredStocks(response.data)
      setDisplayResults(true)
      setFilterCriteriaChanged(false)
      setIsLoading(false)
    } catch (err) {
      setFilteredStocks([])
      setDisplayResults(false)
      setIsLoading(false)
      toast.error("Unable to run the screener. Please try after sometime.")
    }
  }

  const renderSectorSelection = () => {
    const currentScreenerData =
      selectedTab === 0
        ? defaultScreenerData
        : userScreenersData && userScreenersData[selectedTab - 1]?.data // Added checks

    if (!currentScreenerData) {
      // Return null or a placeholder if currentScreenerData is undefined
      return null // Or return <Typography>Loading...</Typography>
    }

    return (
      <FormControl variant="standard" sx={{ m: 1, minWidth: 300 }}>
        <Typography className={classes["selection-header"]}>
          Select sectors...
        </Typography>
        <Select
          className="basic-multi-select"
          classNamePrefix="select"
          isMulti
          isClearable
          isSearchable
          name="allSectors"
          options={allSectors}
          placeholder={"All Sectors"}
          onChange={(options) => {
            const updatedScreenerData = {
              ...currentScreenerData,
              selectedSectors: options,
            }

            if (selectedTab === 0) {
              setDefaultScreenerData(updatedScreenerData)
              setScreenerData(updatedScreenerData)
            } else {
              const updatedUserScreeners = [...userScreenersData]
              updatedUserScreeners[selectedTab - 1].data = updatedScreenerData
              setUserScreenersData(updatedUserScreeners)
              setScreenerData(updatedScreenerData)
            }

            filteredStocks?.length > 0
              ? setFilterCriteriaChanged(true)
              : setFilterCriteriaChanged(false)
            setDisplayResults(false)
          }}
          value={currentScreenerData.selectedSectors}
        />
        {currentScreenerData.selectedSectors.length > 0 && (
          <FormControlLabel
            className={classes["selection-header"]}
            control={
              <Checkbox
                checked={currentScreenerData.excludeSelectedSectors}
                onChange={(event) => {
                  const updatedScreenerData = {
                    ...currentScreenerData,
                    excludeSelectedSectors: event.target.checked,
                  }

                  if (selectedTab === 0) {
                    setDefaultScreenerData(updatedScreenerData)
                    setScreenerData(updatedScreenerData)
                  } else {
                    const updatedUserScreeners = [...userScreenersData]
                    updatedUserScreeners[selectedTab - 1].data =
                      updatedScreenerData
                    setUserScreenersData(updatedUserScreeners)
                    setScreenerData(updatedScreenerData)
                  }
                }}
                color="primary"
              />
            }
            label={
              <Typography variant="body1">
                Exclude the selected sectors
              </Typography>
            }
          />
        )}
      </FormControl>
    )
  }

  const renderClassificationSelection = () => {
    const currentScreenerData =
      selectedTab === 0
        ? defaultScreenerData
        : userScreenersData && userScreenersData[selectedTab - 1]?.data // Added checks

    if (!currentScreenerData) {
      // Return null or a placeholder if currentScreenerData is undefined
      return null // Or return <Typography>Loading...</Typography>
    }

    return (
      <FormControl variant="standard" sx={{ m: 1, minWidth: 250 }}>
        <Typography className={classes["selection-header"]}>
          Select Market Caps...
        </Typography>
        <Select
          className="basic-multi-select"
          classNamePrefix="select"
          isMulti
          isClearable
          isSearchable
          name="allClassifications"
          options={allClassifications}
          placeholder={"All Market Caps"}
          onChange={(options) => {
            const updatedScreenerData = {
              ...currentScreenerData,
              selectedClassifications: options,
            }

            if (selectedTab === 0) {
              setDefaultScreenerData(updatedScreenerData)
              setScreenerData(updatedScreenerData)
            } else {
              const updatedUserScreeners = [...userScreenersData]
              updatedUserScreeners[selectedTab - 1].data = updatedScreenerData
              setUserScreenersData(updatedUserScreeners)
              setScreenerData(updatedScreenerData)
            }

            filteredStocks?.length > 0
              ? setFilterCriteriaChanged(true)
              : setFilterCriteriaChanged(false)
            setDisplayResults(false)
          }}
          value={currentScreenerData.selectedClassifications}
        />
        {currentScreenerData.selectedClassifications.length > 0 && (
          <FormControlLabel
            className={classes["selection-header"]}
            control={
              <Checkbox
                checked={currentScreenerData.excludeSelectedMarketCaps}
                onChange={(event) => {
                  const updatedScreenerData = {
                    ...currentScreenerData,
                    excludeSelectedMarketCaps: event.target.checked,
                  }

                  if (selectedTab === 0) {
                    setDefaultScreenerData(updatedScreenerData)
                    setScreenerData(updatedScreenerData)
                  } else {
                    const updatedUserScreeners = [...userScreenersData]
                    updatedUserScreeners[selectedTab - 1].data =
                      updatedScreenerData
                    setUserScreenersData(updatedUserScreeners)
                    setScreenerData(updatedScreenerData)
                  }
                }}
                color="primary"
              />
            }
            label={
              <Typography variant="body1">
                Exclude the selected market caps
              </Typography>
            }
          />
        )}
      </FormControl>
    )
  }

  const renderFilters = () => {
    const currentScreenerData =
      selectedTab === 0
        ? defaultScreenerData
        : userScreenersData && userScreenersData[selectedTab - 1]?.data

    if (!currentScreenerData || !currentScreenerData.filters) {
      // Added check for currentScreenerData and filters
      return null
    }

    return currentScreenerData.filters.map((filter, index) => (
      <Filter
        key={index + `-${filter?.metric?.metric}`}
        index={index}
        filter={filter}
        onFilterChange={handleFilterChange}
        onDelete={handleDeleteFilter}
        setSelectedSectors={currentScreenerData.selectedSectors}
      />
    ))
  }

  // Check if there are any filters
  const currentScreenerData =
    selectedTab === 0
      ? defaultScreenerData
      : userScreenersData && userScreenersData[selectedTab - 1]?.data

  if (
    currentScreenerData &&
    currentScreenerData.filters &&
    currentScreenerData.filters.length > 0
  ) {
    currentScreenerData.filters.forEach((filter) => {
      if (
        !displayFindStocksButton &&
        filter.metric &&
        filter.metric.value !== "" &&
        filter.operator &&
        filter.operator.value !== "" &&
        filter.input &&
        filter.input.value !== ""
      ) {
        setDisplayFindStocksButton(true)
      }
    })
  }

  return (
    <Box className={classes["main-container"]}>
      <Typography variant="body">
        <p>
          Tired of endless stock research? Our powerful Stock Screener helps you
          cut through the noise and{" "}
          <strong>discover top investment opportunities</strong> tailored to
          your specific criteria.
        </p>
        <p>
          Create, save, and refine <strong>multiple</strong> custom stock
          screeners. Effortlessly filter by sectors, market caps, and metrics to
          pinpoint your ideal investments. <strong>Streamline</strong> your
          research and make smarter decisions in less time.
        </p>
      </Typography>
      {renderTabs()}
      <Box sx={{ display: "flex", justifyContent: "left", mt: 1 }}>
        {selectedTab === 0 && (
          <Tooltip title="Save Screener As.." placement="right">
            <IconButton
              onClick={() => handleSaveScreener(selectedTab)}
              size="small"
            >
              <SaveIcon />
            </IconButton>
          </Tooltip>
        )}
        {selectedTab !== 0 && (
          <>
            <Tooltip title="Save Screener" placement="right">
              <IconButton
                onClick={() => handleSaveScreener(selectedTab)}
                size="small"
              >
                <SaveIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete Screener" placement="right">
              <IconButton
                onClick={() => handleDeleteScreener(selectedTab)}
                size="small"
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </>
        )}
      </Box>
      <Dialog
        open={deleteDialogOpen}
        onClose={handleCancelDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this screener?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={renameDialogOpen} onClose={handleRenameDialogClose}>
        <DialogTitle>
          {selectedTab === 0 ? "Save Screener" : "Rename Screener"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {selectedTab === 0
              ? "Please enter a name for your screener."
              : "Please enter a new name for your screener."}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Screener Name"
            type="text"
            fullWidth
            value={renameInputValue}
            onChange={(e) => setRenameInputValue(e.target.value)}
            error={!!nameLengthError}
            helperText={nameLengthError}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRenameDialogClose}>Cancel</Button>
          <Button
            onClick={handleRenameDialogConfirm}
            disabled={!!nameLengthError}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <div>
        {renderClassificationSelection()}
        {renderSectorSelection()}
      </div>
      <Grid container className={classes["selection-container"]}>
        <Grid item xs={12} sm={6} md={6}>
          {screenerData?.filters?.length === 0 && (
            <Box
              sx={{
                p: { xs: 2, md: 2 },
                m: { xs: 2, md: 2 },
                border: "1px dashed grey",
              }}
            >
              <Typography>
                No filters selected. Please add some filters to get started.
              </Typography>
            </Box>
          )}
        </Grid>
      </Grid>
      {screenerData?.filters?.length > 0 && renderFilters()}
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <Button
          variant="text"
          startIcon={<AddIcon />}
          onClick={handleAddFilter}
          sx={{ width: "150px" }}
        >
          Add Filter
        </Button>
        {displayFindStocksButton && ( // Conditionally render Find Stocks button
          <Button
            variant="contained"
            display="row"
            color="primary"
            onClick={handleFindStocks}
            sx={{ width: "150px" }}
          >
            Find Stocks
          </Button>
        )}
      </Box>
      {isLoading && (
        <Box className="loader">
          <Loading />
        </Box>
      )}
      {displayResults && ( // Conditionally render Find Stocks button
        <FilteredStocks
          filteredStocks={filteredStocks}
          filters={screenerData.filters}
        />
      )}
      {filterCriteriaChanged && ( // Conditionally render Find Stocks button
        <Grid item xs={12} sm={6} md={6}>
          <Alert variant="outlined" severity="warning" sx={{ mt: 5 }}>
            Screening criteria changed !. Please click{" "}
            <strong>FIND STOCKS</strong> to get updated results.
          </Alert>
        </Grid>
      )}
    </Box>
  )
}

export default Screener

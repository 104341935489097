import React, { useEffect, useState, useMemo } from "react"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js"
import { Line } from "react-chartjs-2"
import { Box, Link, Slider, Typography, useMediaQuery } from "@mui/material"
import PropTypes from "prop-types"
import {
  differenceInDays,
  format,
  setDate,
  setMonth,
  subMonths,
  subYears,
  differenceInYears,
  isWithinInterval,
  isValid,
} from "date-fns"
import { defaults } from "chart.js"

import { palette } from "../../../../utils/Palette"
import useStyles from "../styles"
import {
  TextThousandSeparator,
  numFormatter,
} from "../../../../utils/NumberFormatters"

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
)
defaults.font.family = palette.fontFamily

const tabs = [
  "1D",
  "5D",
  "1M",
  "3M",
  "6M",
  "YTD",
  "1Y",
  "3Y",
  "5Y",
  "10Y",
  "MAX",
]
const tabsText = {
  "1D": "1 Day",
  "5D": "5 Days",
  "1M": "1 Month",
  "3M": "3 Months",
  "6M": "6 Months",
  YTD: `${(
    differenceInDays(new Date(), setDate(setMonth(new Date(), 0), 1)) / 365
  ).toFixed(2)} Years`,
  "1Y": "1 Year",
  "3Y": "3 Year",
  "5Y": "5 Years",
  "10Y": "10 Years",
  MAX: "All time",
}

// To display formatted value
const displayValue = (value, ext = "%", isSuffix = true, props) => {
  const formatOpts = {
    thousandSeparator: true,
    fixedDecimalScale: true,
    ...props,
  }
  if (!value) return "-"
  return (
    <TextThousandSeparator
      value={value}
      prefix={!isSuffix && ext}
      suffix={isSuffix && ext}
      thousandSeparator={formatOpts.thousandSeparator}
      fixedDecimalScale={formatOpts.fixedDecimalScale}
    />
  )
}

const checkForNegativeDisplay = (value) => {
  if (value < 0)
    return (
      <span className={"error-txt"}>
        {"( "}
        {displayValue(value * -1)}
        {" )"}
      </span>
    )
  else return <span>{displayValue(value)}</span>
}

function valuetext(value) {
  return `${value}°C`
}

const Linechart = ({ historicalPriceFull, historicalPrice5Min, overview }) => {
  const [chartData, setChartData] = useState(null)
  const [priceRange, setPriceRange] = useState("1D")
  const [priceChange, setPriceChange] = useState(0)
  const [priceChangeCagr, setPriceChangeCagr] = useState(0)
  const [maxPrice, setMaxPrice] = useState(0)
  const [minPrice, setMinPrice] = useState(0)
  const [tabOpt, setTabOpt] = useState(0)
  const classes = useStyles()
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"))
  const isMediumScreen = useMediaQuery((theme) =>
    theme.breakpoints.between("sm", "md")
  )
  const isMediumLargeScreen = useMediaQuery((theme) =>
    theme.breakpoints.between("md", "lg")
  )
  const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up("lg"))

  const historicalPrice5MinReversed = useMemo(
    () => [...(historicalPrice5Min || [])].reverse(),
    [historicalPrice5Min]
  )
  const historicalPriceFullReversed = useMemo(
    () => [...(historicalPriceFull || [])].reverse(),
    [historicalPriceFull]
  )

  const [minValue, setMinValue] = useState(0)
  const [maxValue] = useState(new Date().getFullYear())
  const [rangeValue, setRangeValue] = useState([0, new Date().getFullYear()])
  const minDistance = 1
  const handleRangeChange = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return
    }
    if (newValue[1] - newValue[0] < minDistance) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], rangeValue[1] - minDistance)
        setRangeValue([clamped, clamped + minDistance])
      } else {
        const clamped = Math.max(newValue[1], minDistance)
        setRangeValue([clamped - minDistance, clamped])
      }
    } else {
      setRangeValue(newValue)
    }
  }

  useEffect(
    () => {
      let currentRangeData = []
      let labels = []
      let startDate = format(subMonths(new Date(), 1), "yyyy-MM-dd")
      let borderColor = palette.purple
      let backgroundColor = palette["primary-bg"]
      let timePeriod = 0
      const endDate = format(new Date(), "yyyy-MM-dd")

      // generate data for chart based on given time range
      const getDataSet = (dataSet, startDate) => {
        return dataSet.filter((data) =>
          isWithinInterval(new Date(data.date), {
            start: new Date(startDate),
            end: new Date(endDate),
          })
        )
      }

      // generate data for chart based on given slider range
      const getDataSetByRange = (dataSet, minYear, maxYear) => {
        return dataSet.filter(
          (data) =>
            data.date.slice(0, data.date.indexOf("-")) <= maxYear &&
            data.date.slice(0, data.date.indexOf("-")) >= minYear
        )
      }

      // generate labels for chart based on given time range
      const getLabels = (dataSet, dateFormat) => {
        /**
         * As date string from external API is invalid, adding custom logic
         * 1 - if date is valid, format without modification
         * 2 - if it has both date and time, use first if condition
         * 3 - if it has only date go to else
         *  */
        return dataSet.map((val) => {
          if (isValid(val?.date)) {
            return format(new Date(val?.date), dateFormat)
          }
          /**
           * Sample invalid date - "2022-08-30 09:30:00"
           */
          const dateTimeArr = val?.date?.split(" ")
          const dateArr = dateTimeArr?.[0].split("-")
          if (dateTimeArr?.[1]) {
            const timeArr = dateTimeArr?.[1].split(":")
            return format(
              new Date(
                dateArr[0],
                dateArr[1] - 1, // minus 1 because the array for months starts at 0
                dateArr[2],
                timeArr[0],
                timeArr[1]
              ),
              dateFormat
            )
          } else {
            return format(
              new Date(dateArr[0], dateArr[1] - 1, dateArr[2]),
              dateFormat
            )
          }
        })
      }

      // set chart colors based on given dataset
      const setChartColors = (dataSet) => {
        borderColor = palette["success-dark"]
        backgroundColor = palette["success-bg-1"]
        if (dataSet[0].close > dataSet.at(-1).close) {
          borderColor = palette["error-dark"]
          backgroundColor = palette["error-bg-1"]
        }
      }

      const calcPriceChange = (dataSet, cagrVal) => {
        setMinPrice(Math.min(...dataSet.map((item) => item.close)))
        setMaxPrice(Math.max(...dataSet.map((item) => item.close)))
        setPriceChange(
          ((dataSet.at(-1)?.close - dataSet[0]?.close) / dataSet[0]?.close) *
            100
        )
        let timePeriod

        if (cagrVal === "calculate") {
          const startDate = new Date(dataSet[0]?.date)
          const endDate = new Date(dataSet.at(-1)?.date)
          const daysDifference = differenceInDays(endDate, startDate)
          timePeriod = daysDifference / 365.25 // Use 365.25 for leap year consideration
        } else {
          timePeriod = cagrVal
        }
        setPriceChangeCagr(
          (Math.pow(
            currentRangeData.at(-1)?.close / currentRangeData[0]?.close,
            1 / timePeriod
          ) -
            1) *
            100
        )
      }

      if (
        historicalPriceFullReversed.length &&
        historicalPrice5MinReversed.length
      )
        switch (priceRange) {
          case "1D":
            // 78 being 5min interval data for a day
            currentRangeData = historicalPrice5MinReversed.slice(-78)
            labels = getLabels(currentRangeData, "h:mm a")
            setChartColors(currentRangeData)
            calcPriceChange(currentRangeData, 1 / 365)
            break
          case "5D":
            /**
             * 79 being 5min interval data for a day
             * for 5 days = 5*79
             * */
            currentRangeData = historicalPrice5MinReversed.slice(-389)
            labels = getLabels(currentRangeData, "MMM d h:mm a")
            setChartColors(currentRangeData)
            calcPriceChange(currentRangeData, 5 / 365)
            break
          case "1M":
            startDate = subMonths(new Date(), 1)
            currentRangeData = getDataSet(
              historicalPriceFullReversed,
              startDate
            )
            labels = getLabels(currentRangeData, "MMM d, yyyy")
            setChartColors(currentRangeData)
            calcPriceChange(currentRangeData, 1 / 12)
            break
          case "3M":
            startDate = subMonths(new Date(), 3)
            currentRangeData = getDataSet(
              historicalPriceFullReversed,
              startDate
            )
            labels = getLabels(currentRangeData, "MMM d, yyyy")
            setChartColors(currentRangeData)
            calcPriceChange(currentRangeData, 3 / 12)
            break
          case "6M":
            startDate = format(subMonths(new Date(), 6), "yyyy-MM-dd")
            currentRangeData = getDataSet(
              historicalPriceFullReversed,
              startDate
            )
            labels = getLabels(currentRangeData, "MMM d, yyyy")
            setChartColors(currentRangeData)
            calcPriceChange(currentRangeData, 6 / 12)
            break
          case "YTD":
            startDate = format(
              setDate(setMonth(new Date(), 0), 0),
              "yyyy-MM-dd"
            )
            currentRangeData = getDataSet(
              historicalPriceFullReversed,
              startDate
            )
            labels = getLabels(currentRangeData, "MMM d, yyyy")
            setChartColors(currentRangeData)
            timePeriod =
              differenceInDays(
                new Date(),
                setDate(setMonth(new Date(), 0), 1)
              ) / 365
            calcPriceChange(currentRangeData, timePeriod)
            break
          case "1Y":
            startDate = subYears(new Date(), 1)
            currentRangeData = getDataSet(
              historicalPriceFullReversed,
              startDate
            )
            labels = getLabels(currentRangeData, "MMM d, yyyy")
            setChartColors(currentRangeData)
            calcPriceChange(currentRangeData, 1)
            break
          case "3Y":
            startDate = subYears(new Date(), 3)
            if (rangeValue[0] === startDate.getFullYear()) {
              currentRangeData = getDataSet(
                historicalPriceFullReversed,
                startDate
              )
            } else {
              currentRangeData = getDataSetByRange(
                historicalPriceFullReversed,
                rangeValue[0],
                rangeValue[1]
              )
            }
            labels = getLabels(currentRangeData, "MMM d, yyyy")
            setChartColors(currentRangeData)
            calcPriceChange(currentRangeData, "calculate")
            break
          case "5Y":
            startDate = subYears(new Date(), 5)
            if (rangeValue[0] === startDate.getFullYear()) {
              currentRangeData = getDataSet(
                historicalPriceFullReversed,
                startDate
              )
            } else {
              currentRangeData = getDataSetByRange(
                historicalPriceFullReversed,
                rangeValue[0],
                rangeValue[1]
              )
            }
            labels = getLabels(currentRangeData, "MMM d, yyyy")
            setChartColors(currentRangeData)
            calcPriceChange(currentRangeData, "calculate")
            break
          case "10Y":
            startDate = subYears(new Date(), 10)
            if (rangeValue[0] === startDate.getFullYear()) {
              currentRangeData = getDataSet(
                historicalPriceFullReversed,
                startDate
              )
            } else {
              currentRangeData = getDataSetByRange(
                historicalPriceFullReversed,
                rangeValue[0],
                rangeValue[1]
              )
            }
            labels = getLabels(currentRangeData, "MMM d, yyyy")
            setChartColors(currentRangeData)
            calcPriceChange(currentRangeData, "calculate")
            break
          case "MAX":
            currentRangeData = historicalPriceFullReversed.filter(
              (data, index) =>
                index % 20 === 0 ||
                index === historicalPriceFullReversed.length - 1
            )
            currentRangeData = getDataSetByRange(
              historicalPriceFullReversed,
              rangeValue[0],
              rangeValue[1]
            )
            labels = getLabels(currentRangeData, "MMM d, yyyy")
            setChartColors(currentRangeData)
            calcPriceChange(currentRangeData, "calculate")
            break
          default:
            // 78 being 5min interval data for a day
            currentRangeData = historicalPrice5MinReversed.slice(-78)
            labels = getLabels(currentRangeData, "h:mm a")
            setChartColors(currentRangeData)
            calcPriceChange(currentRangeData, 1 / 365)
        }

      setChartData({
        labels,
        datasets: [
          {
            label: "",
            data: currentRangeData.map((val) => val?.close),
            fill: true,
            borderColor,
            backgroundColor,
            volumeData: currentRangeData.map((val) => val?.volume),
          },
        ],
      })
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      // eslint-disable-next-line react-hooks/exhaustive-deps
      JSON.stringify(historicalPriceFullReversed),
      historicalPriceFullReversed,
      priceRange,
      rangeValue,
    ]
  )

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      point: {
        radius: 0,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          autoSkip: true,
          maxTicksLimit: isSmallScreen
            ? 3
            : isMediumScreen || isMediumLargeScreen
            ? 3
            : 6,
          maxRotation: 0,
          minRotation: 0,
          font: {
            family: `'${palette.fontFamily}', 'Arial', 'sans-serif'`,
          },
        },
      },
      y: {
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          font: {
            family: `'${palette.fontFamily}', 'Arial', 'sans-serif'`,
          },
          maxTicksLimit: isSmallScreen
            ? 3
            : isMediumScreen || isMediumLargeScreen
            ? 5
            : 8,
          // Include a dollar sign in the ticks
          callback: function (value) {
            return Intl.NumberFormat("hi", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 0,
            })
              .format(value)
              .replace(/^(\D+)/, "$1 ")
          },
          precision: 0,
        },
      },
    },
    interaction: {
      mode: "x",
    },
    plugins: {
      tooltip: {
        displayColors: false,
        callbacks: {
          title: function (item) {
            const titleValue = item[0].formattedValue
            return "USD $" + titleValue
          },
          label: function (item) {
            const newLineArray = []
            newLineArray.push(item.label)
            newLineArray.push(
              "Volume: " + numFormatter(item.dataset.volumeData[item.dataIndex])
            )
            return newLineArray
          },
          labelTextColor: function (item) {
            return "#757575"
          },
        },
        mode: "index",
        intersect: false,
        backgroundColor: "white",
        borderColor: "#E0E0E0",
        borderWidth: 1,
        titleColor: "black",
        titleFont: {
          family: "'Montserrat', 'Arial', sans-serif",
        },
        bodyFont: {
          family: "'Montserrat', 'Arial', sans-serif",
        },
        yAlign: "bottom",
      },
      legend: {
        display: false,
      },
      crosshair: {
        line: {
          color: "#FFFFFF", // crosshair line color
          width: 1, // crosshair line width
        },
        sync: {
          enabled: false,
        },
        zoom: {
          enabled: false,
        },
      },
      title: {
        display: false,
      },
    },
    layout: {
      padding: 10,
    },
    borderWidth: 1,
  }

  return (
    <Box className={classes["chart-component"]}>
      <Box className={classes["chart-title-container"]}>
        <Box className={classes["row-container"]}>
          <Typography className={classes.name}>{overview?.name}</Typography>
          <Typography className={classes.symbol}>
            {overview?.tickerId}
          </Typography>
          <Typography className={classes.exchange}>
            {overview?.exchange}
          </Typography>
        </Box>
        <Typography variant="body2" sx={{ wordBreak: "break-word" }}>
          <span className={priceChange < 0 ? "error-txt" : "success-txt"}>
            {checkForNegativeDisplay(priceChange)}
          </span>
          {["1D", "5D", "1M", "6M", "1Y"].includes(priceRange) && (
            <>
              {" total price return over "}
              {tabsText[priceRange]}
            </>
          )}
          {["YTD"].includes(priceRange) && <>{" price return YTD "}</>}
          {["3Y", "5Y", "10Y", "MAX"].includes(priceRange) && (
            <>{" total price return "}</>
          )}

          {!["1D", "5D", "1M", "6M", "YTD", "1Y"].includes(priceRange) && (
            <>
              {" at a CAGR of "}
              <span className={priceChange < 0 ? "error-txt" : "success-txt"}>
                {checkForNegativeDisplay(priceChangeCagr)}
              </span>
            </>
          )}
          {["3Y", "5Y", "10Y", "MAX"].includes(priceRange) && (
            <>
              {" between "}
              {rangeValue[0]} {"&"} {rangeValue[1]}
            </>
          )}
          <>
            {" : Price Range: ["}
            {displayValue(minPrice, "$", false)} {" - "}{" "}
            {displayValue(maxPrice, "$", false)}
            {" ]"}
          </>
        </Typography>
      </Box>
      <Box className={classes["btn-container"]}>
        {tabs.map((tab, index) => (
          <Link
            key={tab}
            className={classes["range-tab"]}
            onClick={() => {
              setPriceRange(tab)
              setTabOpt(index)
              // set slider range for 5Y and 10Y tabs
              // eslint-disable-next-line no-lone-blocks
              {
                if (tab === "3Y") {
                  if (
                    differenceInYears(
                      new Date(historicalPriceFullReversed.at(-1).date),
                      new Date(historicalPriceFullReversed[0].date)
                    ) > 3
                  ) {
                    setMinValue(subYears(new Date(), 3).getFullYear())
                    setRangeValue([
                      subYears(new Date(), 3).getFullYear(),
                      new Date().getFullYear(),
                    ])
                  } else {
                    setMinValue(
                      new Date(
                        historicalPriceFullReversed.at(0).date
                      ).getFullYear()
                    )
                    setRangeValue([
                      new Date(
                        historicalPriceFullReversed.at(0).date
                      ).getFullYear(),
                      new Date().getFullYear(),
                    ])
                  }
                }
                if (tab === "5Y") {
                  if (
                    differenceInYears(
                      new Date(historicalPriceFullReversed.at(-1).date),
                      new Date(historicalPriceFullReversed[0].date)
                    ) > 5
                  ) {
                    setMinValue(subYears(new Date(), 5).getFullYear())
                    setRangeValue([
                      subYears(new Date(), 5).getFullYear(),
                      new Date().getFullYear(),
                    ])
                  } else {
                    setMinValue(
                      new Date(
                        historicalPriceFullReversed.at(0).date
                      ).getFullYear()
                    )
                    setRangeValue([
                      new Date(
                        historicalPriceFullReversed.at(0).date
                      ).getFullYear(),
                      new Date().getFullYear(),
                    ])
                  }
                }
                if (tab === "10Y") {
                  if (
                    differenceInYears(
                      new Date(historicalPriceFullReversed.at(-1).date),
                      new Date(historicalPriceFullReversed[0].date)
                    ) > 10
                  ) {
                    setMinValue(subYears(new Date(), 10).getFullYear())
                    setRangeValue([
                      subYears(new Date(), 10).getFullYear(),
                      new Date().getFullYear(),
                    ])
                  } else {
                    setMinValue(
                      new Date(
                        historicalPriceFullReversed.at(0).date
                      ).getFullYear()
                    )
                    setRangeValue([
                      new Date(
                        historicalPriceFullReversed.at(0).date
                      ).getFullYear(),
                      new Date().getFullYear(),
                    ])
                  }
                }
                if (tab === "MAX") {
                  setMinValue(
                    new Date(historicalPriceFullReversed[0].date).getFullYear()
                  )
                  setRangeValue([
                    new Date(historicalPriceFullReversed[0].date).getFullYear(),
                    new Date().getFullYear(),
                  ])
                }
              }
            }}
          >
            {tab}
            <span
              className={`${
                tabOpt === index ? classes.active : classes.inactive
              }`}
            ></span>
          </Link>
        ))}
      </Box>
      <Box width="100%" height="100%" minHeight="250px">
        {chartData && <Line options={options} data={chartData} />}
      </Box>
      {["3Y", "5Y", "10Y", "MAX"].includes(priceRange) && (
        <Box width="100%">
          <Slider
            size="small"
            getAriaLabel={() => "Minimum distance shift"}
            value={rangeValue}
            onChange={handleRangeChange}
            valueLabelDisplay="on"
            getAriaValueText={valuetext}
            min={minValue}
            max={maxValue}
            disableSwap
            marks
            color="success"
            sx={{
              "& .MuiSlider-thumb": {
                width: 20, // Adjust size
                height: 20,
                border: "2px solid white", // Add border
              },
              "& .MuiSlider-thumb:hover, & .MuiSlider-thumb.Mui-focusVisible": {
                boxShadow: "0 0 0 8px rgba(156, 39, 176, 0.16)", // Add hover effect
              },
            }}
          />
        </Box>
      )}
    </Box>
  )
}

export default Linechart

Linechart.propTypes = {
  historicalPriceFull: PropTypes.array.isRequired,
  historicalPrice5Min: PropTypes.array.isRequired,
  overview: PropTypes.object.isRequired,
}

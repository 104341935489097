import React from "react"
import { Typography, Grid, useMediaQuery, Stack, Link } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { CopyrightOutlined, X, YouTube } from "@mui/icons-material"
import Cookies from "js-cookie"

import useStyles from "./styles"
import { styled } from "@mui/material/styles"
import clsx from "clsx"

const StyledLogo = styled("img")(({ theme }) => ({
  height: 35,
  marginLeft: theme.spacing(1),
  marginTop: theme.spacing(-2),
}))

const Footer = () => {
  const classes = useStyles()
  const largeOrXLargeScreen = useMediaQuery((theme) =>
    theme.breakpoints.up("lg")
  ) // Check for lg and above
  const navigate = useNavigate()

  return (
    <Grid container direction="row" className={classes.footer}>
      <Grid
        item
        xs={12}
        sm={12}
        md={3}
        lg={3}
        display="flex"
        flexDirection="column"
        justifyContent={largeOrXLargeScreen ? "start" : "center"}
        alignItems={largeOrXLargeScreen ? "start" : "center"}
      >
        <Typography
          className={classes["footer-text"]}
          style={{ paddingBottom: largeOrXLargeScreen ? "0px" : "16px" }}
        >
          <CopyrightOutlined style={{ marginRight: "8px" }} />{" "}
          {new Date().getFullYear()} Copyright. All rights reserved.
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={2}
        lg={2}
        display="flex"
        flexDirection="column"
        justifyContent={largeOrXLargeScreen ? "start" : "center"}
        alignItems={largeOrXLargeScreen ? "start" : "center"}
      >
        <Stack direction="row" spacing={0} alignItems="center">
          <Typography
            variant="body2"
            className={classes["footer-text"]}
            style={{ paddingBottom: largeOrXLargeScreen ? "0px" : "16px" }}
          >
            Supported by
          </Typography>
          <Link href="https://openqueue.us/" target="_blank" underline="none">
            <StyledLogo
              src={`${window.location.origin}/Assets/400PngdpiLogoCropped.png`}
              alt="Open Queue"
            />
          </Link>
        </Stack>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={4}
        lg={4}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection={largeOrXLargeScreen ? "row" : "column"} // Conditional row/column
      >
        <Typography
          className={clsx(classes["footer-text"], "cursor-pointer")}
          style={{ marginRight: largeOrXLargeScreen ? "20px" : "0px" }}
        >
          <span onClick={() => navigate("/guides/disclaimer")}>Disclaimer</span>
        </Typography>
        <Typography
          className={clsx(classes["footer-text"], "cursor-pointer")}
          style={{ marginRight: largeOrXLargeScreen ? "20px" : "0px" }}
        >
          <span onClick={() => navigate("/guides/privacy-policy")}>
            Privacy policy
          </span>
        </Typography>
        <Typography
          className={clsx(classes["footer-text"], "cursor-pointer")}
          style={{ marginRight: largeOrXLargeScreen ? "20px" : "0px" }}
        >
          <span onClick={() => navigate("/guides/terms-of-service")}>
            Terms of Service
          </span>
        </Typography>
        <Typography
          className={clsx(classes["footer-text"], "cursor-pointer")}
          style={{
            marginRight: largeOrXLargeScreen ? "20px" : "0px",
            paddingBottom: largeOrXLargeScreen ? "0px" : "16px",
          }}
        >
          <span
            onClick={() => window.open("mailto:support@letsvalueinvest.com")}
          >
            Contact Us
          </span>
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={3}
        lg={3}
        display="flex"
        justifyContent={largeOrXLargeScreen ? "end" : "center"}
        alignItems="center"
        flexDirection="column"
      >
        <Typography className={classes["footer-icons"]}>
          <X
            style={{ marginRight: "16px", fontSize: "25px" }}
            onClick={() => window.open("https://x.com/letsvalueinvest")}
          />
          <YouTube
            style={{ marginRight: "16px", fontSize: "35px" }}
            onClick={() =>
              window.open(
                "https://www.youtube.com/channel/UCj__Yox6lLRZySg7noJsj0w"
              )
            }
          />
          <Link
            href="https://www.producthunt.com/posts/lets-value-invest?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-lets-value-invest"
            target="_blank"
            underline="none"
            rel="noreferrer"
          >
            <StyledLogo
              src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=557875&theme=light"
              alt="Lets Value Invest - Learn, Analyze, Invest: Your Value Investing Toolkit | Product Hunt"
              style={{
                width: "250px",
                height: "40px",
                marginTop: "0px",
                marginLeft: "0px",
              }}
            />
          </Link>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography className={classes["version"]}>
          version: {Cookies.get("lvi_version")}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default Footer

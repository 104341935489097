import React from "react"
import { Box, Typography } from "@mui/material"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { ArrowUpward, ArrowDownward } from "@mui/icons-material"

import useStyles from "./styles"
import { TextThousandSeparator } from "../../../../../utils/NumberFormatters"
import { getClassification } from "../../../../../utils/misc"

const TickerHeader = ({ data }) => {
  const classes = useStyles()

  // To display formatted value
  const displayValue = (
    value,
    ext = "",
    thousandSeparator = true,
    isSuffix = false,
    fixedDecimalScale = false
  ) => {
    if (!value) return "-"
    if (isSuffix) {
      value = value.toFixed(2)
    } else {
      const absValue = Math.abs(value)
      if (absValue > 999 && absValue <= 1000000) {
        // convert to K for number from > 1000 < 1 million
        value = (value / 1000).toFixed(2)
        isSuffix = true
        ext = "K"
      } else if (absValue >= 1000000 && absValue < 1000000000) {
        // convert to K for number from > 1000 < 1 million
        value = (value / 1000000).toFixed(2)
        isSuffix = true
        ext = "M"
      } else if (absValue >= 1000000000 && absValue < 1000000000000) {
        // convert to K for number from > 1000 < 1 million
        value = (value / 1000000000).toFixed(2)
        isSuffix = true
        ext = "B"
      } else if (absValue >= 1000000000000) {
        // convert to K for number from > 1000 < 1 million
        value = (value / 1000000000000).toFixed(2)
        isSuffix = true
        ext = "T"
      }
    }
    return (
      <TextThousandSeparator
        value={value}
        prefix={!isSuffix && ext}
        suffix={isSuffix && ext}
        thousandSeparator={thousandSeparator}
        fixedDecimalScale={fixedDecimalScale}
      />
    )
  }

  return (
    <Box className={classes["header-container"]}>
      {data?.image && (
        <Box
          component="img"
          sx={{
            maxHeight: 60,
            maxWidth: 80,
          }}
          alt=""
          src={data?.image}
        />
      )}
      <Box className={classes.container}>
        <Box className={classes["row-container"]}>
          <Typography className={classes.name}>
            <Link
              to={`/ticker/${data?.tickerId}/peers`}
              className={classes.name}
            >
              {data?.name}
            </Link>
          </Typography>
          <Typography className={classes.symbol}>{data?.tickerId}</Typography>
          <Typography className={classes.exchange}>{data?.exchange}</Typography>
        </Box>
        <Box className={classes["row-container"]}>
          <Typography className={classes.price}>
            {displayValue(data?.price, "$", true, false, true)}
          </Typography>
          <Typography
            className={`${classes.priceChangePer} ${
              data?.changesPercentage < 0 ? "error-txt" : "success-txt"
            }`}
          >
            {data?.changesPercentage > 0 ? <ArrowUpward /> : <ArrowDownward />}
            {data?.changesPercentage > 0 ? "+" : ""}
            {displayValue(data?.changesPercentage, "%", true, true, true)}
          </Typography>
          <Typography
            className={`${classes.priceChange} ${
              data?.change < 0 ? "error-txt" : "success-txt"
            }`}
          >
            {data?.change > 0 && <span>+</span>}
            {displayValue(data?.change, "", true, true, true)}
          </Typography>
        </Box>
        <Box className={classes["row-container"]}>
          <Typography className={classes.price}>Market Cap:</Typography>
          <Typography className={classes.marketCap}>
            {displayValue(data?.marketCap, "$", true, false, true)} (
            {getClassification(data?.marketCap)})
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default TickerHeader

TickerHeader.propTypes = {
  data: PropTypes.object.isRequired,
}

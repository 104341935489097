import { makeStyles } from "@mui/styles"

export default makeStyles((theme) => ({
  "header-container": {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "20px 32px 18px 32px",
    /*  position: "fixed",
    width: "100%", */
    zIndex: 1,
    backgroundColor: theme.palette.white,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "start",
    paddingLeft: "20px",
  },
  "row-container": {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "baseline",
  },
  name: {
    fontSize: "1.25rem !important",
    fontWeight: "bold",
  },
  symbol: {
    fontSize: "12px !important",
    color: theme.palette["grey-2"],
    paddingLeft: 8,
  },
  exchange: {
    fontSize: "12px !important",
    color: theme.palette["grey-2"],
    paddingLeft: 2,
  },
  price: {
    fontSize: "24px !important",
    fontWeight: "500 !important",
    paddingRight: 4,
    /*color: "black !important",
    backgroundColor: "#ca0000 !important",*/
  },
  priceChange: {
    fontSize: "16px !important",
    paddingLeft: 8,
    fontWeight: "500 !important",
  },
  priceChangePer: {
    fontSize: "16px !important",
    padding: "2px 8px",
    fontWeight: "500 !important",
    display: "flex",
    alignItems: "baseline",
    flexWrap: "wrap",
    borderRadius: "8px",
  },
  icon: {
    position: "relative",
    top: "5px",
  },
  overallScore: {
    display: "flex",
    alignItems: "center",
    marginTop: "-10px",
  },
  earnings: {
    display: "flex",
    alignItems: "center",
    fontSize: "14px !important",
    color: theme.palette["grey-2"],
    fontStyle: "italic",
  },
  "score-5": {
    fontWeight: "600",
    textTransform: "capitalize",
    backgroundColor: theme.palette["score-5-bg"],
    color: theme.palette.white,
    padding: "2px 14px",
    borderRadius: "10px",
  },
  "score-4": {
    fontWeight: "600",
    textTransform: "capitalize",
    backgroundColor: theme.palette["score-4-bg"],
    color: theme.palette.white,
    padding: "2px 14px",
    borderRadius: "10px",
  },
  "score-3": {
    fontWeight: "600",
    textTransform: "capitalize",
    backgroundColor: theme.palette["score-3-bg"],
    color: theme.palette.white,
    padding: "2px 14px",
    borderRadius: "10px",
  },
  "score-2": {
    fontWeight: "600",
    textTransform: "capitalize",
    backgroundColor: theme.palette["score-1-bg"],
    color: theme.palette.white,
    padding: "2px 14px",
    borderRadius: "10px",
  },
  "score-1": {
    fontWeight: "600",
    textTransform: "capitalize",
    backgroundColor: theme.palette["score-2-bg"],
    color: theme.palette.white,
    padding: "2px 14px",
    borderRadius: "10px",
  },
  "total-score-5": {
    fontWeight: "600",
    fontSize: "16px",
    backgroundColor: theme.palette["tot-score-5-bg"],
    color: theme.palette["score-5-bg"],
    padding: "2px 14px",
    borderRadius: "10px",
  },
  "total-score-4": {
    fontWeight: "600",
    backgroundColor: theme.palette["tot-score-4-bg"],
    color: theme.palette["score-4-bg"],
    padding: "2px 14px",
    borderRadius: "10px",
  },
  "total-score-3": {
    fontWeight: "600",
    backgroundColor: theme.palette["tot-score-3-bg"],
    color: theme.palette["score-3-bg"],
    padding: "2px 14px",
    borderRadius: "10px",
  },
  "total-score-2": {
    fontWeight: "600",
    backgroundColor: theme.palette["tot-score-2-bg"],
    color: theme.palette["score-2-bg"],
    padding: "2px 14px",
    borderRadius: "10px",
  },
  "total-score-1": {
    fontWeight: "600",
    backgroundColor: theme.palette["tot-score-1-bg"],
    color: theme.palette["score-1-bg"],
    padding: "2px 14px",
    borderRadius: "10px",
  },
  "author-name": {
    color: theme.palette["grey-2"],
    fontStyle: "italic",
  },
}))

import React from "react"
import {
  FormControl,
  Typography,
  IconButton,
  Grid,
  OutlinedInput,
  InputAdornment,
  Tooltip,
} from "@mui/material"
import Select from "react-select"
import DeleteIcon from "@mui/icons-material/Delete"
import useStyles from "./styles"
import { metricsForScreener, operators, values } from "./selectOptions.js"
const metrics = []

metricsForScreener.forEach((metricForScreener) => {
  if (
    metricForScreener.header === "Key Insights" ||
    metricForScreener.header === "Share Price Movement"
  ) {
    metrics.push({
      label: metricForScreener.header,
      options: metricForScreener?.options.map((option) => {
        return {
          label: option.label,
          value: option.value,
          type: option.type,
          suffix: option.suffix,
        }
      }),
    })
  } else {
    const allOptions = []
    metricForScreener?.options.forEach((option) => {
      allOptions.push({
        label: option.label + " 10 Years",
        value: option.value + ".tenYear",
        type: option.type,
        suffix: option.suffix,
      })
      allOptions.push({
        label: option.label + " 5 Years",
        value: option.value + ".fiveYear",
        type: option.type,
        suffix: option.suffix,
      })
      allOptions.push({
        label: option.label + " 3 Years",
        value: option.value + ".threeYear",
        type: option.type,
        suffix: option.suffix,
      })
      allOptions.push({
        label: option.label + " TTM",
        value: option.value + ".ttm",
        type: option.type,
        suffix: option.suffix,
      })
    })
    metrics.push({
      label: metricForScreener.header,
      options: allOptions,
    })
  }
})

const Filter = ({
  index,
  filter,
  onFilterChange,
  onDelete,
  setSelectedSectors,
}) => {
  const classes = useStyles()
  const handleMetricChange = (event) => {
    onFilterChange(index, "metric", event)
  }

  const handleOperatorChange = (event) => {
    onFilterChange(index, "operator", event)
  }

  const handleInputChange = (event) => {
    onFilterChange(index, "input", event)
  }

  const handleDeleteFilter = () => {
    onDelete(index)
  }

  const handleTextBoxChange = (event) => {
    // Allow only digits, decimal point, and an optional leading negative sign
    const newValue = event.target.value.replace(/[^0-9.]|^[-.]+/g, "")
    onFilterChange(index, "input", newValue)
  }

  return (
    <Grid
      key={index}
      style={{ display: "flex", alignItems: "center" }}
      container
      className={classes["selection-container"]}
    >
      <FormControl variant="standard" sx={{ m: 1, minWidth: 250 }}>
        <Typography className={classes["selection-header"]}>
          Choose a metric...
        </Typography>
        <Select
          className="basic-single"
          classNamePrefix="select"
          isClearable
          isSearchable
          name="metrics"
          options={metrics}
          placeholder="Choose a metric..." // Set default value
          onChange={(option) => {
            handleMetricChange(option)
          }}
          value={filter.metric}
        />
      </FormControl>
      <FormControl variant="standard" sx={{ m: 1, minWidth: 250 }}>
        <Typography className={classes["selection-header"]}>
          Operator
        </Typography>
        <Select
          className="basic-single"
          classNamePrefix="select"
          isClearable
          isSearchable
          name="operators"
          options={operators}
          placeholder="Select operator..." // Set default value
          onChange={(option) => {
            handleOperatorChange(option)
          }}
          value={filter.operator}
        />
      </FormControl>
      {filter?.metric?.type !== "Single" && (
        <FormControl variant="standard" sx={{ m: 1, minWidth: 250 }}>
          <Typography className={classes["selection-header"]}>
            Select a Value...
          </Typography>
          <Select
            className="basic-single"
            classNamePrefix="select"
            isClearable
            isSearchable
            name="values"
            options={values}
            placeholder="Select value..." // Set default value
            onChange={(option) => {
              handleInputChange(option)
            }}
            value={filter.input}
          />
        </FormControl>
      )}

      {filter?.metric?.type === "Single" && (
        <FormControl sx={{ m: 1, width: "10ch" }} variant="outlined">
          <Typography className={classes["selection-header"]}>
            Enter a value...
          </Typography>

          <OutlinedInput
            size="small"
            id="outlined-adornment-weight"
            endAdornment={
              filter?.metric?.suffix && (
                <InputAdornment position="end">
                  {filter?.metric?.suffix}
                </InputAdornment>
              )
            } // Conditionally render endAdornment if suffix is set
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              "aria-label": "weight",
              maxLength: 5,
            }}
            value={
              typeof filter?.input === "string"
                ? filter.input
                : filter?.input?.value ?? ""
            }
            onChange={handleTextBoxChange}
          />
        </FormControl>
      )}
      <Tooltip title="Delete this filter">
        <IconButton
          aria-label="delete"
          sx={{ mt: 3 }}
          onClick={handleDeleteFilter}
        >
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    </Grid>
  )
}

export default Filter

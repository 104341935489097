import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Paper,
  Typography,
  Tooltip,
} from "@mui/material"
import React from "react"
import PropTypes from "prop-types"
import moment from "moment"
import WbTwilightIcon from "@mui/icons-material/WbTwilight"
import NightlightRoundIcon from "@mui/icons-material/NightlightRound"
import { useNavigate } from "react-router-dom"

import useStyles from "../styles"

const EarningsCalendar = ({ data }) => {
  const classes = useStyles()
  const navigate = useNavigate()

  return (
    <Box>
      <TableContainer component={Paper} className={classes["table-container"]}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell colSpan={3}>
                <div className={classes["header-text"]}>Upcoming earnings</div>
                <div className={classes["sub-header"]}>
                  Based on stocks you follow
                </div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.slice(0, 10)?.map((row, index) => (
              <TableRow key={`${row.title}${index}`}>
                <TableCell align="left">
                  {/* Date and Day */}
                  <Box
                    display="flex"
                    flexDirection="column"
                    className={classes["earningsCalendar"]}
                  >
                    <div className={classes["calendar-month"]}>
                      {moment(row?.date).format("MMM").toUpperCase()}
                    </div>
                    <div className={classes["calendar-day"]}>
                      {moment(row?.date).format("DD")}
                    </div>
                  </Box>
                </TableCell>
                <TableCell align="left">
                  {/* Company Symbol and Name */}
                  <Typography>
                    <div>
                      <span
                        onClick={() => {
                          navigate(`/ticker/${row.symbol}/overview`)
                        }}
                        className={classes["custom-link"]}
                      >
                        {row?.name}
                      </span>
                    </div>
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Tooltip
                    title={
                      row?.time === "amc"
                        ? "After Market Close"
                        : "Before Market Open"
                    }
                  >
                    {row?.time === "amc" ? (
                      <NightlightRoundIcon />
                    ) : (
                      <WbTwilightIcon />
                    )}
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default EarningsCalendar

EarningsCalendar.propTypes = {
  data: PropTypes.array.isRequired,
}

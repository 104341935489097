import React, { useContext, useEffect, useState } from "react"
import {
  Box,
  Typography,
  Tooltip,
  Button,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import DoneIcon from "@mui/icons-material/Done"
import AddIcon from "@mui/icons-material/Add"
import PropTypes from "prop-types"
import { ArrowUpward, ArrowDownward, ExpandMore } from "@mui/icons-material"
import AppDataContext from "../../../context/data"
import RatingChip from "../../Chip/Rating"
import ScoreChip from "../../Chip/Score"
import { format } from "date-fns"
import { getClassification } from "../../../utils/misc"

import useStyles from "./styles"
import { displayValue } from "../../../utils/NumberFormatters"
import {
  getFavoriteStatusAndCount,
  removeTicker,
  addOrUpdateTicker,
} from "../../../services/user"
import { toast } from "react-toastify"
import Quote from "../../Quote"

const TickerHeader = ({ data }) => {
  const classes = useStyles()
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"))
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("lg"))
  const isTabletScreen = useMediaQuery(theme.breakpoints.down("md"))
  const { watchlists } = useContext(AppDataContext)
  const { setWatchlists } = useContext(AppDataContext)
  const [isFavorited, setIsFavorited] = useState(false)
  const userId = window.sessionStorage.getItem("userId")

  useEffect(() => {
    //API call to check if the ticker is favorited for the user
    const getFavoriteDetails = async () => {
      try {
        const resp = await getFavoriteStatusAndCount(userId, data?.tickerId)
        setIsFavorited(resp?.data?.isFavorited)
      } catch (err) {}
    }
    getFavoriteDetails()
  }, [data?.tickerId, userId])

  function updateWatchlists(
    watchlists,
    updatedWatchlist,
    tickerId,
    isAdding,
    watchlistId
  ) {
    return watchlists.map((watchlist) => {
      if (watchlist.id === watchlistId) {
        const existingTickerIndex = watchlist.tickers.findIndex(
          (t) => t.tickerId === tickerId
        )

        const addedOrUpdatedTicker = updatedWatchlist.tickers.find(
          (ticker) => ticker.tickerId === tickerId
        )

        if (isAdding) {
          // If ticker doesn't exist, add it
          if (existingTickerIndex === -1) {
            return {
              ...watchlist,
              tickers: [
                ...watchlist.tickers,
                {
                  ...addedOrUpdatedTicker,
                  id: addedOrUpdatedTicker._id,
                  overallScore: data?.overallScore,
                  name: data?.keyInsightsData?.info.name,
                  price: data?.keyInsightsData?.info.price,
                  change: data?.keyInsightsData?.info.change,
                  changesPercentage:
                    data?.keyInsightsData?.info.changesPercentage,
                  ytdReturn: data?.keyInsightsData?.pastReturns.ytd,
                  yearlyReturn: data?.keyInsightsData?.pastReturns["1Y"],
                  fiveYearReturn: data?.keyInsightsData?.pastReturns["5Y"],
                  yearLow: data?.keyInsightsData?.info?.yearLow,
                  percentChangeFromYearLow:
                    data?.keyInsightsData?.info?.percentChangeFromYearLow,
                  yearHigh: data?.keyInsightsData?.info?.yearHigh,
                  percentChangeFromYearHigh:
                    data?.keyInsightsData?.info?.percentChangeFromYearHigh,
                  growthScore: data?.keyInsightsData?.growthRates?.score,
                  managementEffectivenessScore:
                    data?.keyInsightsData?.managementEffectiveness?.score,
                  financialHealthScore:
                    data?.keyInsightsData?.financialHealth?.score,
                  valuationMultiplesScore:
                    data?.keyInsightsData?.valuationMultiples?.score,
                  profitabilityScore:
                    data?.keyInsightsData.profitability?.score,
                  moatScore: data?.keyInsightsData?.moat?.score,
                  businessQualityScore:
                    data?.keyInsightsData?.businessQuality?.score,
                  dividendScore: data?.keyInsightsData?.dividend?.score,
                },
              ],
            }
          } else {
            // If ticker exists, update it (e.g., add/update additional properties)
            const updatedTickers = [...watchlist.tickers]
            updatedTickers[existingTickerIndex] = {
              ...updatedTickers[existingTickerIndex],
              // Add or update properties here
            }
            return {
              ...watchlist,
              tickers: updatedTickers,
            }
          }
        } else {
          // Remove the ticker
          return {
            ...watchlist,
            tickers: watchlist.tickers.filter((t) => t.tickerId !== tickerId),
          }
        }
      } else {
        return watchlist
      }
    })
  }

  const handleFavoriteClick = async () => {
    try {
      if (isFavorited) {
        // Unfavorite the ticker
        const response = await removeTicker(
          userId,
          watchlists[0]._id,
          data?.tickerId
        )
        if (response.status === 200) {
          // Update watchlist state in context
          const updatedWatchlists = updateWatchlists(
            watchlists,
            response?.data,
            data?.tickerId,
            false,
            watchlists[0].id // Assuming the first watchlist is the target
          )
          setWatchlists(updatedWatchlists)
          toast.success("You are no longer following " + data?.tickerId)
        } else {
          toast.error(
            "There was a problem unfollowing this ticker " +
              data?.tickerId +
              ". Please try again."
          )
        }
      } else {
        // Favorite the ticker
        const response = await addOrUpdateTicker(
          userId,
          watchlists[0]._id,
          data?.tickerId,
          JSON.stringify({ notes: "", priceTarget: 0 })
        )
        if (response.status === 200) {
          // Update watchlist state in context
          const updatedWatchlists = updateWatchlists(
            watchlists,
            response?.data,
            data?.tickerId,
            true,
            watchlists[0].id // Assuming the first watchlist is the target
          )
          setWatchlists(updatedWatchlists)
          toast.success("You have started following " + data?.tickerId)
        } else {
          toast.error(
            "There was a problem following this ticker " +
              data?.tickerId +
              ". Please try again."
          )
        }
      }
      setIsFavorited(!isFavorited)
    } catch (error) {
      console.error(error)
      // Handle error, e.g., show an error message to the user
    }
  }

  return (
    <Box display="flex" flexDirection={isSmallScreen ? "column" : "row"}>
      <Box
        sx={{
          width: isSmallScreen
            ? "100%"
            : isMediumScreen // Use the variable
            ? "400px"
            : "600px",
          flexShrink: 0,
        }}
      >
        <Box className={classes["header-container"]}>
          {data?.image && (
            <Box
              component="img"
              sx={{
                maxHeight: 60,
                maxWidth: 80,
              }}
              alt=""
              src={data?.image}
            />
          )}
          <Box className={classes.container}>
            <Box className={classes["row-container"]}>
              <Typography className={classes.name}>
                {data?.name}
                <span className={classes.symbol}>
                  {data?.tickerId} |
                  <span className={classes.exchange}>{data?.exchange}</span>
                </span>
              </Typography>
            </Box>
            <Box className={classes["row-container"]}>
              <Typography className={classes.price}>
                {displayValue(data?.price, "$", true, false, true, false)}
              </Typography>
              <Typography
                className={`${classes.priceChangePer} ${
                  data?.changesPercentage < 0
                    ? "percentage-down-txt"
                    : "percentage-up-txt"
                }`}
              >
                {data?.changesPercentage > 0 ? (
                  <ArrowUpward sx={{ fontSize: 20 }} className={classes.icon} />
                ) : (
                  <ArrowDownward
                    sx={{ fontSize: 20 }}
                    className={classes.icon}
                  />
                )}
                {data?.changesPercentage > 0 ? "+" : ""}
                {displayValue(data?.changesPercentage, "%", true, true, true)}
              </Typography>
              <Typography
                className={`${classes.priceChange} ${
                  data?.change < 0 ? "error-txt" : "success-txt"
                }`}
              >
                {data?.change > 0 ? `+${data?.change}` : data.change}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          className={classes.overallScore}
        >
          {data?.image && (
            <Box sx={{ ml: 4 }}>
              <RatingChip score={data?.overallScore}></RatingChip>
            </Box>
          )}
          {data?.image && (
            <Box sx={{ ml: 1 }}>
              <ScoreChip score={data?.overallScore}></ScoreChip>
            </Box>
          )}
          <Box sx={{ ml: 2 }}>
            <Button
              size="small"
              color="error"
              variant="outlined"
              sx={{
                mt: 0,
                pt: 0,
                pb: 0,
                fontSize: 14,
                textTransform: "none",
              }}
              onClick={handleFavoriteClick}
            >
              {isFavorited ? (
                <>
                  <DoneIcon />
                  Following
                </>
              ) : (
                <>
                  <AddIcon />
                  Follow
                </>
              )}
            </Button>
          </Box>
          <Box sx={{ ml: 1, pt: "3px" }}>
            <Tooltip
              title={
                <React.Fragment>
                  <Typography variant="body2">
                    <strong>Market Cap: </strong>$
                    {displayValue(data?.bigNumbers?.marketCap)} (
                    {getClassification(data?.profile?.marketCap)})
                  </Typography>
                  <Typography variant="body2">
                    <strong>Enterprise Value: </strong>$
                    {displayValue(data?.bigNumbers?.enterpriseValue)}
                  </Typography>
                  <Typography variant="body2">
                    <strong>52 week price range: </strong>
                    {displayValue(data?.shareStats?.yearLow)}
                    {" - "}
                    {displayValue(data?.shareStats?.yearHigh)}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Earnings Yield: </strong>
                    {displayValue(
                      data?.currentYields?.earningsYield,
                      "%",
                      "true",
                      "true",
                      "true",
                      "true"
                    )}
                  </Typography>
                  <Typography variant="body2">
                    <strong>FCF Yield: </strong>
                    {displayValue(
                      data?.currentYields?.freeCashFlowYield,
                      "%",
                      "true",
                      "true",
                      "true",
                      "true"
                    )}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Dividend Yield: </strong>
                    {displayValue(
                      data?.currentYields?.forwardDividendYield,
                      "%",
                      "true",
                      "true",
                      "true",
                      "true"
                    )}
                  </Typography>
                  <Typography variant="caption" display="block" gutterBottom>
                    {data?.profile?.sector}/{data?.profile?.industry}
                  </Typography>
                </React.Fragment>
              }
              placement="right"
            >
              <ExpandMore
                sx={{ transform: "rotate(270deg)" }}
                className={"cursor-pointer"}
                fontSize="medium"
              ></ExpandMore>
            </Tooltip>
          </Box>
        </Box>
        <Box display="flex" flexDirection="row" sx={{ mt: 1, mb: 1, ml: 4 }}>
          <span className={classes.earnings}>
            {new Date(data?.earningsAnnouncement).getTime() >=
            new Date().getTime()
              ? "Next earning: "
              : "Last earning: "}
            {data?.earningsAnnouncement
              ? format(new Date(data?.earningsAnnouncement), "MMMM d, yyyy")
              : ""}
          </span>
        </Box>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          minWidth: "300px",
          paddingLeft: isTabletScreen ? 2 : 2,
          paddingRight: isTabletScreen ? 2 : isMediumScreen ? 2 : 2,
        }}
      >
        <Quote></Quote>
      </Box>
    </Box>
  )
}

export default TickerHeader

TickerHeader.propTypes = {
  data: PropTypes.object.isRequired,
}

import React, { useContext, useState, useEffect, useRef } from "react"
import { Box } from "@mui/material"
import { useNavigate, useLocation } from "react-router-dom"
import decode from "jwt-decode"
import { toast } from "react-toastify"
import useStyles from "./styles"
import AppDataContext from "../../context/data"
import Header from "../Header"
import Footer from "../Footer"
import { getFormatedTerms } from "../../utils/strapi"
import { getQuotes, getStrapiTerms } from "../../services/core"
import { getWatchlists, createWatchlist } from "../../services/user"

const Layout = ({ children }) => {
  const classes = useStyles()

  const [tokenId] = useState(window.sessionStorage.getItem("tokenId"))
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { setStrapiTerms } = useContext(AppDataContext)
  const { setQuotes } = useContext(AppDataContext)
  const { setWatchlists } = useContext(AppDataContext)

  useEffect(() => {
    const getTerms = async () => {
      try {
        const resp = await getStrapiTerms()
        setStrapiTerms(getFormatedTerms(resp?.data))
      } catch (err) {}
    }
    getTerms()
  }, [setStrapiTerms])

  useEffect(() => {
    const loadQuotes = async () => {
      try {
        const resp = await getQuotes()
        setQuotes(resp?.data)
      } catch (err) {}
    }
    loadQuotes()
  }, [setQuotes])

  useEffect(() => {
    // getWatchlists
    const userId = window.sessionStorage.getItem("userId")
    const getUsersWatchlists = async () => {
      try {
        let resp = await getWatchlists(userId)
        // if there are no watchlists then create the default one
        if (resp?.data?.length === 0) {
          resp = await createWatchlist(
            userId,
            JSON.stringify({
              name: "Default",
            })
          )
          setWatchlists([resp?.data])
        } else {
          setWatchlists(resp?.data)
        }
      } catch (err) {}
    }
    if (userId && userId !== "null") getUsersWatchlists()
  }, [setWatchlists])

  useEffect(() => {
    try {
      if (
        tokenId &&
        !(
          pathname.includes("compound-interest") ||
          pathname.includes("cagr") ||
          pathname.includes("guides") ||
          pathname.includes("reading") ||
          pathname.includes("indexes")
        )
      ) {
        const decodedToken = decode(tokenId)
        if (decodedToken.exp * 1000 < new Date().getTime()) {
          window.sessionStorage.setItem("currentUser", null)
          window.sessionStorage.setItem("tokenId", null)
          navigate("/")
        }
      }
    } catch (err) {
      setTimeout(() => {
        toast.warn("Please login to continue.")
      })
      navigate("/")
    }
  }, [pathname, tokenId, navigate])

  return (
    <Box className={classes["home-container"]}>
      <Box className={classes["header-container"]}>
        <Header />
      </Box>
      <Box className={classes["main-container"]} flex={1}>
        {children}
        {/* Render children here */}
      </Box>
      <Box className={classes["footer-container"]}>
        <Footer />
      </Box>
    </Box>
  )
}

export default Layout

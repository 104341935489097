import React from "react"
import { Box } from "@mui/material"
import { useParams } from "react-router-dom"

import CAGR from "./CAGR"
import Compound from "./Compound"
import useStyles from "./styles"

const Calculators = () => {
  const classes = useStyles()
  const { type } = useParams()

  return (
    <Box
      className={classes["calculator-container"]}
      sx={{ p: { xs: 1, sm: 2 } }}
    >
      {type === "cagr" && <CAGR />}
      {type === "compound-interest" && <Compound />}
    </Box>
  )
}

export default Calculators

import React from "react"
import PropTypes from "prop-types"
import {
  Box,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  Tooltip,
} from "@mui/material"
import { Chart } from "react-chartjs-2"
import {
  Chart as ChartJS,
  BarController,
  BarElement,
  LinearScale,
  CategoryScale,
  Tooltip as ChartJSTooltip,
  Legend,
} from "chart.js"
import { palette } from "../../../../utils/Palette"
import useStyles from "../styles"
import InfoIcon from "@mui/icons-material/Info"

// Register ChartJS components
ChartJS.register(
  BarController,
  BarElement,
  LinearScale,
  CategoryScale,
  ChartJSTooltip,
  Legend
)

const RevenueEstimates = ({ analystEstimates }) => {
  const classes = useStyles()

  // Early return checks
  if (!analystEstimates || analystEstimates.length === 0) {
    console.log("No estimates data available")
    return null
  }

  const tickerId = analystEstimates[0]?.symbol
  // Check if tickerId exists in URL path
  const pathParts = window.location.pathname.split("/")
  const symbolInPath =
    tickerId &&
    pathParts.some((part) => part.toUpperCase() === tickerId.toUpperCase())

  if (!symbolInPath) {
    console.log(`Ticker ${tickerId} not found in URL path`)
    return null
  }

  // Format revenue number based on magnitude
  const formatRevenue = (value) => {
    const numValue = Number(value)
    if (numValue >= 1000000000) return `$${(numValue / 1000000000).toFixed(1)}B`
    if (numValue >= 1000000) return `$${(numValue / 1000000).toFixed(1)}M`
    if (numValue >= 1000) return `$${(numValue / 1000).toFixed(1)}K`
    return `$${numValue.toFixed(1)}`
  }

  // Calculate percentage change between years
  const calculatePercentageChanges = (data) => {
    const changes = []
    for (let i = 1; i < data.length; i++) {
      const change = ((data[i] - data[i - 1]) / data[i - 1]) * 100
      changes.push(change)
    }
    return changes
  }

  // Precise CAGR calculation using actual dates
  const calculateCAGR = (startValue, endValue, startDate, endDate) => {
    if (!startDate || !endDate || startValue === 0) return null
    if (startValue < 0 || endValue < 0) return null

    const start = new Date(startDate)
    const end = new Date(endDate)
    const years = (end - start) / (1000 * 60 * 60 * 24 * 365.25)

    if (years <= 0) return null

    try {
      return (Math.pow(endValue / startValue, 1 / years) - 1) * 100
    } catch (e) {
      return null
    }
  }

  const CAGRDisplay = ({ value }) => {
    if (value === null || isNaN(value)) {
      return (
        <Tooltip title="CAGR cannot be calculated with negative values or invalid time period">
          <span style={{ display: "inline-flex", alignItems: "center" }}>
            N/A <InfoIcon fontSize="small" sx={{ ml: 0.5 }} />
          </span>
        </Tooltip>
      )
    }
    return <span>{value.toFixed(2)}%</span>
  }

  // Custom plugin to show percentage changes above bars
  const percentageChangePlugin = {
    id: "percentageChange",
    afterDatasetsDraw(chart) {
      const { ctx, data } = chart
      data.datasets.forEach((dataset, i) => {
        const meta = chart.getDatasetMeta(i)
        if (meta.visible && dataset.percentageChanges) {
          meta.data.forEach((bar, index) => {
            if (index > 0 && dataset.percentageChanges[index - 1]) {
              const change = dataset.percentageChanges[index - 1]
              const x = bar.x
              const y = bar.y - 5

              ctx.fillStyle = dataset.borderColor
              ctx.font = "bold 10px Arial"
              ctx.textAlign = "center"
              ctx.fillText(`${change.toFixed(1)}%`, x, y)
            }
          })
        }
      })
    },
  }

  // Register the custom plugin
  ChartJS.register(percentageChangePlugin)

  // Prepare data for the chart
  const prepareChartData = () => {
    console.log("Original analystEstimates:", analystEstimates)

    const pastActual = analystEstimates?.find((e) => e?.isActual)
    if (!pastActual) {
      console.log("No actual data found")
      return { labels: [], datasets: [], cagrData: { low: 0, avg: 0, high: 0 } }
    }

    const actualYear = new Date(pastActual.date).getFullYear()
    console.log("Actual year found:", actualYear)

    // Use Set to ensure unique years
    const uniqueYears = new Set([actualYear])

    // Filter and sort estimates while maintaining unique years
    const filteredEstimates = analystEstimates
      .filter((estimate) => {
        if (estimate?.isActual) return false
        const year = new Date(estimate.date).getFullYear()
        if (year >= actualYear && !uniqueYears.has(year)) {
          uniqueYears.add(year)
          return true
        }
        return false
      })
      .sort((a, b) => new Date(a.date) - new Date(b.date))

    const allData = [pastActual, ...filteredEstimates]
    const labels = Array.from(uniqueYears).sort()
    console.log("Final labels after deduplication:", labels)

    // Prepare dataset values
    const lowData = labels.map((year) => {
      const item = allData.find((d) => new Date(d.date).getFullYear() === year)
      return item
        ? item.isActual
          ? item.actualRevenue
          : item.estimatedRevenueLow
        : null
    })

    const avgData = labels.map((year) => {
      const item = allData.find((d) => new Date(d.date).getFullYear() === year)
      return item
        ? item.isActual
          ? item.actualRevenue
          : item.estimatedRevenueAvg
        : null
    })

    const highData = labels.map((year) => {
      const item = allData.find((d) => new Date(d.date).getFullYear() === year)
      return item
        ? item.isActual
          ? item.actualRevenue
          : item.estimatedRevenueHigh
        : null
    })

    console.log("Low estimate data:", lowData)
    console.log("Avg estimate data:", avgData)
    console.log("High estimate data:", highData)

    // Calculate changes
    const lowChanges = calculatePercentageChanges(
      lowData.filter((v) => v !== null)
    )
    const avgChanges = calculatePercentageChanges(
      avgData.filter((v) => v !== null)
    )
    const highChanges = calculatePercentageChanges(
      highData.filter((v) => v !== null)
    )

    console.log("Percentage changes (low):", lowChanges)
    console.log("Percentage changes (avg):", avgChanges)
    console.log("Percentage changes (high):", highChanges)

    // Calculate CAGR from actual through last estimate
    const cagrData =
      allData.length > 1
        ? {
            low: calculateCAGR(
              pastActual.actualRevenue,
              lowData[lowData.length - 1],
              pastActual.date,
              allData[allData.length - 1].date
            ),
            avg: calculateCAGR(
              pastActual.actualRevenue,
              avgData[avgData.length - 1],
              pastActual.date,
              allData[allData.length - 1].date
            ),
            high: calculateCAGR(
              pastActual.actualRevenue,
              highData[highData.length - 1],
              pastActual.date,
              allData[allData.length - 1].date
            ),
          }
        : { low: 0, avg: 0, high: 0 }

    console.log("Calculated CAGR:", cagrData)

    return {
      labels,
      datasets: [
        {
          label: "Actual Revenue",
          data: labels.map((year) =>
            year === actualYear ? pastActual.actualRevenue : null
          ),
          backgroundColor: palette["purple"],
          borderWidth: 0,
          barPercentage: 10,
          categoryPercentage: 0.05,
        },
        {
          label: "Low Estimate",
          data: labels.map((year) =>
            year === actualYear
              ? null
              : allData.find((d) => new Date(d.date).getFullYear() === year)
                  ?.estimatedRevenueLow || null
          ),
          backgroundColor: palette["score-1-bg"],
          borderColor: palette["score-1-bg"],
          borderWidth: 1,
          barPercentage: 0.5,
          categoryPercentage: 0.8,
          percentageChanges: lowChanges,
        },
        {
          label: "Average Estimate",
          data: labels.map((year) =>
            year === actualYear
              ? null
              : allData.find((d) => new Date(d.date).getFullYear() === year)
                  ?.estimatedRevenueAvg || null
          ),
          backgroundColor: palette["score-3-bg"],
          borderColor: palette["score-3-bg"],
          borderWidth: 1,
          barPercentage: 0.5,
          categoryPercentage: 0.8,
          percentageChanges: avgChanges,
        },
        {
          label: "High Estimate",
          data: labels.map((year) =>
            year === actualYear
              ? null
              : allData.find((d) => new Date(d.date).getFullYear() === year)
                  ?.estimatedRevenueHigh || null
          ),
          backgroundColor: palette["score-5-bg"],
          borderColor: palette["score-5-bg"],
          borderWidth: 1,
          barPercentage: 0.5,
          categoryPercentage: 0.8,
          percentageChanges: highChanges,
        },
      ],
      meta: {
        actualYear,
      },
      analystData: labels.map((year) => {
        const item = allData.find(
          (d) => new Date(d.date).getFullYear() === year
        )
        return {
          year,
          isActual: item?.isActual || false,
          numAnalysts: item?.numberAnalystEstimatedRevenue || 0,
        }
      }),
      cagrData,
    }
  }

  const chartData = prepareChartData()
  console.log("Final chartData:", chartData)

  // Calculate y-axis minimum
  const allValues = chartData.datasets.flatMap((dataset) =>
    dataset.data.filter((value) => value !== null && !isNaN(value))
  )
  const minValue = allValues.length > 0 ? Math.min(...allValues) : 0
  console.log("Calculated minValue:", minValue)

  // Function to calculate nice rounded intervals
  const calculateRoundedMin = (value) => {
    if (value === 0) return 0
    if (value >= 1000000000) {
      const inBillions = value / 1000000000
      const rounded = Math.floor(inBillions * 10) / 10
      return (rounded > 0 ? rounded : 0.1) * 1000000000
    } else if (value >= 1000000) {
      const inMillions = value / 1000000
      const rounded = Math.floor(inMillions)
      return (rounded > 0 ? rounded : 1) * 1000000
    } else if (value >= 1000) {
      const inThousands = value / 1000
      const rounded = Math.floor(inThousands)
      return (rounded > 0 ? rounded : 1) * 1000
    }
    return Math.floor(value) || 1
  }

  const yAxisMin = calculateRoundedMin(minValue * 0.9)
  console.log("Calculated yAxisMin:", yAxisMin)

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        offset: true,
        grid: {
          display: false,
        },
        afterFit: function (scale) {
          // Add fixed padding of 40px on both sides (adjust this number as needed)
          scale.paddingLeft = 10
          scale.paddingRight = 10
        },
        ticks: {
          display: true,
          tickLength: 0,
          tickColor: "transparent",
          callback: function (value, index) {
            const year = this.getLabelForValue(index)
            const meta = chartData.analystData[index]
            return meta && !meta.isActual && meta.numAnalysts
              ? [`${year}`, `${meta.numAnalysts} analysts`]
              : year
          },
        },
      },
      y: {
        beginAtZero: false,
        min: yAxisMin,
        title: {
          display: true,
          text: "Revenue Estimate",
        },
        ticks: {
          callback: function (value) {
            return formatRevenue(value)
          },
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            if (context.dataset.label === "Actual Revenue") {
              return `Actual: ${formatRevenue(context.raw)}`
            }
            return `${context.dataset.label}: ${formatRevenue(context.raw)}`
          },
          afterLabel: function (context) {
            const changes = context.dataset.percentageChanges
            const index = context.dataIndex
            if (index > 0 && changes && changes[index - 1]) {
              return `YoY Change: ${changes[index - 1].toFixed(1)}%`
            }
            return null
          },
          footer: (tooltipItems) => {
            const dataIndex = tooltipItems[0].dataIndex
            const meta = chartData.analystData[dataIndex]
            return meta && !meta.isActual && meta.numAnalysts
              ? `Based on ${meta.numAnalysts} analysts`
              : ""
          },
        },
      },
      legend: {
        position: "top",
      },
    },
  }

  console.log("Final chartOptions:", chartOptions)

  return (
    <Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="revenue estimates table">
          <TableHead>
            <TableRow>
              <TableCell colSpan={6}>
                <div className={classes["main-header"]}>
                  {tickerId} Revenue Estimates
                </div>
                <div className={classes["sub-header"]}>
                  Analyst Consensus Revenue Forecasts & Growth Projections.
                </div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell colSpan={6} sx={{ p: 0 }}>
                <Box
                  sx={{
                    height: "400px",
                    width: "100%",
                    minWidth: `${chartData.labels.length * 80}px`,
                    p: 2,
                  }}
                >
                  <Chart
                    type="bar"
                    data={chartData}
                    options={chartOptions}
                    plugins={[percentageChangePlugin]}
                  />
                </Box>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>CAGR</TableCell>
              <TableCell
                align="center"
                colSpan={
                  chartData.labels.filter(
                    (year) => year > chartData.meta.actualYear
                  ).length
                }
              >
                <Box display="flex" justifyContent="space-around">
                  <Typography
                    variant="body2"
                    sx={{ color: palette["score-1-bg"], fontWeight: "bold" }}
                  >
                    Low: <CAGRDisplay value={chartData.cagrData.low} />
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ color: palette["score-3-bg"], fontWeight: "bold" }}
                  >
                    Avg: <CAGRDisplay value={chartData.cagrData.avg} />
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ color: palette["score-5-bg"], fontWeight: "bold" }}
                  >
                    High: <CAGRDisplay value={chartData.cagrData.high} />
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

RevenueEstimates.propTypes = {
  analystEstimates: PropTypes.array.isRequired,
}

export default RevenueEstimates

// Routing/index.js
import React from "react"

import { Route, Routes, Navigate } from "react-router-dom"
import "react-toastify/dist/ReactToastify.css"

import Landing from "../../components/Landing"
import Loading from "../../components/Loading"
import Layout from "../../components/Layout"
import Home from "../../components/Home"
import ProtectedRoute from "../../components/ProtectedRoute"
import Calculators from "../../components/Calculators"
import Guides from "../../components/Guides"
import Ticker from "../../components/Ticker"
import Overview from "../../components/Ticker/Overview"
import Visualizer from "../../components/Visualizer"
import LogsViewer from "../../components/LogsViewer"
import Screener from "../../components/Screener"
import Watchlists from "../../components/Watchlists"
import Reading from "../../components/Reading"
import Constituents from "../../components/Constituents"
import NotFound from "../../components/NotFound"

const Routing = () => {
  return (
    <React.Suspense fallback={<Loading />}>
      <Routes>
        <Route
          path="/"
          name="Landing"
          element={
            <Layout>
              <Landing />
            </Layout>
          }
        />
        <Route
          path="/home"
          name="Home"
          element={
            <ProtectedRoute>
              <Layout>
                <Home />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/calculators/:type"
          name="Calculators"
          element={
            <ProtectedRoute>
              <Layout>
                <Calculators />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/indexes/:type"
          name="Indexes"
          element={
            <ProtectedRoute>
              <Layout>
                <Constituents />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/reading"
          name="Reading"
          element={
            <ProtectedRoute>
              <Layout>
                <Reading />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/guides/:type"
          name="Guides"
          element={
            <ProtectedRoute>
              <Layout>
                <Guides />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/ticker/:tickerId/*"
          name="Ticker"
          element={
            <ProtectedRoute>
              <Layout>
                <Ticker />
              </Layout>
            </ProtectedRoute>
          }
        >
          <Route
            path="overview"
            name="Overview"
            element={
              <ProtectedRoute>
                <Overview />
              </ProtectedRoute>
            }
          />
        </Route>
        <Route
          path="/visualizer"
          name="Visualizer"
          element={
            <ProtectedRoute>
              <Layout>
                <Visualizer />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/screener"
          name="Screener"
          element={
            <ProtectedRoute>
              <Layout>
                <Screener />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/watchlists"
          name="Watchlists"
          element={
            <ProtectedRoute>
              <Layout>
                <Watchlists />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/logs"
          name="LogsViewer"
          element={
            <ProtectedRoute>
              <Layout>
                <LogsViewer />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/*"
          element={
            <Navigate
              to={window.location.pathname.replace("/dashboard", "")}
              replace
            />
          }
        />
        <Route
          path="*"
          element={
            <Layout>
              <NotFound />
            </Layout>
          }
        />
      </Routes>
    </React.Suspense>
  )
}

export default Routing

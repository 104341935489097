import React, { useContext, useState, useEffect } from "react"
import { Box, Typography } from "@mui/material"
import { FormatQuote } from "@mui/icons-material"
import { useLocation } from "react-router-dom"
import AppDataContext from "../../context/data"
import useStyles from "./styles" // Assuming your styles are in a "styles.js" file

const Quote = () => {
  const classes = useStyles()
  const { quotes } = useContext(AppDataContext)
  const [randomQuote, setRandomQuote] = useState("")
  const { pathname } = useLocation()

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * quotes.length)
    setRandomQuote(quotes[randomIndex])
  }, [pathname, quotes])

  return (
    <Box>
      <Typography>
        <Box
          sx={{
            p: 2,
            mt: { md: 3 },
            mb: 1,
            maxWidth: "lg",
            border: "1px dashed grey",
            backgroundColor: "lightYellow",
            minWidth: "300px",
          }}
        >
          <FormatQuote sx={{ transform: "rotate(180deg)" }} />
          {randomQuote?.description}
          <FormatQuote />
          <span className={classes["author-name"]}>
            - {randomQuote?.author}.
          </span>
        </Box>
      </Typography>
    </Box>
  )
}

export default Quote

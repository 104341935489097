import React from "react"
import PropTypes from "prop-types"
import {
  Box,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  Tooltip,
} from "@mui/material"
import { Chart } from "react-chartjs-2"
import {
  Chart as ChartJS,
  BarController,
  BarElement,
  LinearScale,
  CategoryScale,
  Tooltip as ChartJSTooltip,
  Legend,
} from "chart.js"
import { palette } from "../../../../utils/Palette"
import useStyles from "../styles"
import InfoIcon from "@mui/icons-material/Info"

// Register ChartJS components
ChartJS.register(
  BarController,
  BarElement,
  LinearScale,
  CategoryScale,
  ChartJSTooltip,
  Legend
)

const EPSEstimates = ({ analystEstimates }) => {
  const classes = useStyles()

  // Early return checks
  if (!analystEstimates || analystEstimates.length === 0) {
    console.log("No estimates data available")
    return null
  }

  const tickerId = analystEstimates[0]?.symbol
  // Check if tickerId exists in URL path
  const pathParts = window.location.pathname.split("/")
  const symbolInPath =
    tickerId &&
    pathParts.some((part) => part.toUpperCase() === tickerId.toUpperCase())

  if (!symbolInPath) {
    console.log(`Ticker ${tickerId} not found in URL path`)
    return null
  }

  // Calculate percentage change between years
  const calculatePercentageChanges = (data) => {
    const changes = []
    for (let i = 1; i < data.length; i++) {
      const change = ((data[i] - data[i - 1]) / Math.abs(data[i - 1])) * 100
      changes.push(change)
    }
    return changes
  }

  // Calculate CAGR (Compound Annual Growth Rate)
  const calculateCAGR = (startValue, endValue, startDate, endDate) => {
    // Return null if calculation isn't possible
    if (!startDate || !endDate || startValue === 0) return null

    // Check for negative values
    if (startValue < 0 || endValue < 0) return null

    const start = new Date(startDate)
    const end = new Date(endDate)
    const years = (end - start) / (1000 * 60 * 60 * 24 * 365.25)

    if (years <= 0) return null

    try {
      return (Math.pow(endValue / startValue, 1 / years) - 1) * 100
    } catch (e) {
      return null // Return null for any calculation errors
    }
  }

  const CAGRDisplay = ({ value }) => {
    if (value === null || isNaN(value)) {
      return (
        <Tooltip title="CAGR cannot be calculated with negative values or invalid time period">
          <span style={{ display: "inline-flex", alignItems: "center" }}>
            N/A <InfoIcon fontSize="small" style={{ marginLeft: 4 }} />
          </span>
        </Tooltip>
      )
    }
    return <span>{value.toFixed(2)}%</span>
  }

  // Custom plugin to show percentage changes above bars
  const percentageChangePlugin = {
    id: "percentageChange",
    afterDatasetsDraw(chart) {
      const { ctx, data } = chart
      data.datasets.forEach((dataset, i) => {
        const meta = chart.getDatasetMeta(i)
        if (meta.visible && dataset.percentageChanges) {
          meta.data.forEach((bar, index) => {
            if (index > 0 && dataset.percentageChanges[index - 1]) {
              const change = dataset.percentageChanges[index - 1]
              const x = bar.x
              const y = bar.y - 5

              ctx.fillStyle = dataset.borderColor
              ctx.font = "bold 10px Arial"
              ctx.textAlign = "center"
              ctx.fillText(`${change.toFixed(1)}%`, x, y)
            }
          })
        }
      })
    },
  }

  // Register the custom plugin
  ChartJS.register(percentageChangePlugin)

  // Prepare data for the chart
  const prepareChartData = () => {
    const pastActual = analystEstimates?.find((e) => e?.isActual)
    if (!pastActual) {
      return { labels: [], datasets: [], cagrData: { low: 0, avg: 0, high: 0 } }
    }

    const actualYear = new Date(pastActual.date).getFullYear()
    const uniqueYears = new Set([actualYear])

    const filteredEstimates = analystEstimates
      .filter((estimate) => {
        if (estimate?.isActual) return false
        const year = new Date(estimate.date).getFullYear()
        if (year >= actualYear && !uniqueYears.has(year)) {
          uniqueYears.add(year)
          return true
        }
        return false
      })
      .sort((a, b) => new Date(a.date) - new Date(b.date))

    const allData = [pastActual, ...filteredEstimates]
    const labels = Array.from(uniqueYears).sort()

    // Prepare dataset values
    const lowData = labels.map((year) => {
      const item = allData.find((d) => new Date(d.date).getFullYear() === year)
      return item?.isActual ? item.actualEps : item?.estimatedEpsLow || null
    })

    const avgData = labels.map((year) => {
      const item = allData.find((d) => new Date(d.date).getFullYear() === year)
      return item?.isActual ? item.actualEps : item?.estimatedEpsAvg || null
    })

    const highData = labels.map((year) => {
      const item = allData.find((d) => new Date(d.date).getFullYear() === year)
      return item?.isActual ? item.actualEps : item?.estimatedEpsHigh || null
    })

    // Calculate changes
    const lowChanges = calculatePercentageChanges(
      lowData.filter((v) => v !== null)
    )
    const avgChanges = calculatePercentageChanges(
      avgData.filter((v) => v !== null)
    )
    const highChanges = calculatePercentageChanges(
      highData.filter((v) => v !== null)
    )

    // Calculate CAGR
    const cagrData =
      allData.length > 1
        ? {
            low: calculateCAGR(
              pastActual.actualEps,
              lowData[lowData.length - 1],
              pastActual.date,
              allData[allData.length - 1].date
            ),
            avg: calculateCAGR(
              pastActual.actualEps,
              avgData[avgData.length - 1],
              pastActual.date,
              allData[allData.length - 1].date
            ),
            high: calculateCAGR(
              pastActual.actualEps,
              highData[highData.length - 1],
              pastActual.date,
              allData[allData.length - 1].date
            ),
          }
        : { low: 0, avg: 0, high: 0 }

    return {
      labels,
      datasets: [
        {
          label: "Actual EPS",
          data: labels.map((year) =>
            year === actualYear ? pastActual.actualEps : null
          ),
          backgroundColor: palette["purple"],
          borderWidth: 0,
          barPercentage: 10,
          categoryPercentage: 0.05,
        },
        {
          label: "Low Estimate",
          data: labels.map((year) =>
            year === actualYear
              ? null
              : allData.find((d) => new Date(d.date).getFullYear() === year)
                  ?.estimatedEpsLow || null
          ),
          backgroundColor: palette["score-1-bg"],
          borderColor: palette["score-1-bg"],
          borderWidth: 1,
          barPercentage: 0.5,
          categoryPercentage: 0.8,
          percentageChanges: lowChanges,
        },
        {
          label: "Average Estimate",
          data: labels.map((year) =>
            year === actualYear
              ? null
              : allData.find((d) => new Date(d.date).getFullYear() === year)
                  ?.estimatedEpsAvg || null
          ),
          backgroundColor: palette["score-3-bg"],
          borderColor: palette["score-3-bg"],
          borderWidth: 1,
          barPercentage: 0.5,
          categoryPercentage: 0.8,
          percentageChanges: avgChanges,
        },
        {
          label: "High Estimate",
          data: labels.map((year) =>
            year === actualYear
              ? null
              : allData.find((d) => new Date(d.date).getFullYear() === year)
                  ?.estimatedEpsHigh || null
          ),
          backgroundColor: palette["score-5-bg"],
          borderColor: palette["score-5-bg"],
          borderWidth: 1,
          barPercentage: 0.5,
          categoryPercentage: 0.8,
          percentageChanges: highChanges,
        },
      ],
      meta: {
        actualYear,
      },
      analystData: labels.map((year) => {
        const item = allData.find(
          (d) => new Date(d.date).getFullYear() === year
        )
        return {
          year,
          isActual: item?.isActual || false,
          numAnalysts: item?.numberAnalystsEstimatedEps || 0,
        }
      }),
      cagrData,
    }
  }

  const chartData = prepareChartData()

  // Calculate y-axis minimum
  const allValues = chartData.datasets.flatMap((dataset) =>
    dataset.data.filter((value) => value !== null && !isNaN(value))
  )
  const minValue = allValues.length > 0 ? Math.min(...allValues) : 0

  // Function to calculate nice rounded intervals
  const calculateRoundedMin = (value) => {
    if (value >= 100) {
      return Math.floor(value / 10) * 10
    } else if (value >= 10) {
      return Math.floor(value)
    } else if (value >= 1) {
      return Math.floor(value * 10) / 10
    } else {
      const magnitude = Math.pow(10, Math.floor(Math.log10(value)))
      const rounded = Math.floor(value / magnitude) * magnitude
      return rounded > 0 ? rounded : magnitude
    }
  }

  const yAxisMin = calculateRoundedMin(minValue * 0.8) // Slightly less buffer

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        offset: true, // Key fix for positioning
        grid: {
          display: false,
        },
        ticks: {
          display: true,
          tickLength: 0,
          tickColor: "transparent",
          callback: function (value, index) {
            const year = this.getLabelForValue(index)
            const meta = chartData.analystData[index]
            return meta && !meta.isActual && meta.numAnalysts
              ? [`${year}`, `${meta.numAnalysts} analysts`]
              : year
          },
        },
      },
      y: {
        beginAtZero: false,
        min: yAxisMin,
        title: {
          display: true,
          text: "EPS Estimate ($)",
        },
        ticks: {
          callback: function (value) {
            return `$${value.toFixed(2)}`
          },
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            if (context.dataset.label === "Actual EPS") {
              return `Actual: $${context.raw.toFixed(2)}`
            }
            return `${context.dataset.label}: $${context.raw.toFixed(2)}`
          },
          afterLabel: function (context) {
            const changes = context.dataset.percentageChanges
            const index = context.dataIndex
            if (index > 0 && changes && changes[index - 1]) {
              return `YoY Change: ${changes[index - 1].toFixed(1)}%`
            }
            return null
          },
          footer: (tooltipItems) => {
            const dataIndex = tooltipItems[0].dataIndex
            const meta = chartData.analystData[dataIndex]
            return meta && !meta.isActual && meta.numAnalysts
              ? `Based on ${meta.numAnalysts} analysts`
              : ""
          },
        },
      },
      legend: {
        position: "top",
      },
    },
  }

  return (
    <Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="eps estimates table">
          <TableHead>
            <TableRow>
              <TableCell colSpan={6}>
                <div className={classes["main-header"]}>
                  {tickerId} EPS Estimates
                </div>
                <div className={classes["sub-header"]}>
                  Analyst Consensus EPS Forecasts & Growth Projections.
                </div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell colSpan={6} sx={{ p: 0 }}>
                <Box
                  sx={{
                    height: "400px",
                    width: "100%",
                    minWidth: `${chartData.labels.length * 100}px`,
                    p: 2,
                  }}
                >
                  <Chart
                    type="bar"
                    data={chartData}
                    options={chartOptions}
                    plugins={[percentageChangePlugin]}
                  />
                </Box>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>CAGR</TableCell>
              <TableCell
                align="center"
                colSpan={
                  chartData.labels.filter(
                    (year) => year > chartData.meta.actualYear
                  ).length
                }
              >
                <Box display="flex" justifyContent="space-around">
                  <Typography
                    variant="body2"
                    sx={{
                      color: palette["score-1-bg"],
                      fontWeight: "bold",
                      display: "flex",
                      alignItems: "center",
                      gap: "4px",
                    }}
                  >
                    Low: <CAGRDisplay value={chartData.cagrData.low} />
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      color: palette["score-3-bg"],
                      fontWeight: "bold",
                      display: "flex",
                      alignItems: "center",
                      gap: "4px",
                    }}
                  >
                    Avg: <CAGRDisplay value={chartData.cagrData.avg} />
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      color: palette["score-5-bg"],
                      fontWeight: "bold",
                      display: "flex",
                      alignItems: "center",
                      gap: "4px",
                    }}
                  >
                    High: <CAGRDisplay value={chartData.cagrData.high} />
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

EPSEstimates.propTypes = {
  analystEstimates: PropTypes.array.isRequired,
}

export default EPSEstimates

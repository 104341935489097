import axios from "axios"

const baseURL = `${process.env.REACT_APP_BASE_API}`

export const getAllScreeners = async (userId) => {
  return axios
    .get(`${baseURL}/api/v1/users/${userId}/screeners`)
    .catch((err) => {
      throw new Error(err)
    })
}

export const createScreener = async (userId, reqBody) => {
  return axios
    .post(`${baseURL}/api/v1/users/${userId}/screeners`, reqBody, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .catch((err) => {
      throw new Error(err)
    })
}

export const updateScreener = async (userId, screenerId, reqBody) => {
  return axios
    .put(`${baseURL}/api/v1/users/${userId}/screeners/${screenerId}`, reqBody, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .catch((err) => {
      throw new Error(err)
    })
}

export const deleteScreener = async (userId, screenerId) => {
  return axios
    .delete(`${baseURL}/api/v1/users/${userId}/screeners/${screenerId}`)
    .catch((err) => {
      throw new Error(err)
    })
}

export const getWatchlists = async (userId) => {
  return axios
    .get(`${baseURL}/api/v1/users/${userId}/watchlists/`)
    .catch((err) => {
      throw new Error(err)
    })
}

export const createWatchlist = async (userId, reqBody) => {
  return axios
    .post(`${baseURL}/api/v1/users/${userId}/watchlists`, reqBody, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .catch((err) => {
      throw new Error(err)
    })
}

export const getFavoriteStatusAndCount = async (userId, tickerId) => {
  return axios
    .get(`${baseURL}/api/v1/users/${userId}/stocks/${tickerId}/favorite-status`)
    .catch((err) => {
      throw new Error(err)
    })
}

export const removeTicker = async (userId, watchlistId, tickerId) => {
  return axios
    .delete(
      `${baseURL}/api/v1/users/${userId}/watchlists/${watchlistId}/stocks/${tickerId}`
    )
    .catch((err) => {
      throw new Error(err)
    })
}

export const addOrUpdateTicker = async (
  userId,
  watchlistId,
  tickerId,
  reqBody
) => {
  return axios
    .post(
      `${baseURL}/api/v1/users/${userId}/watchlists/${watchlistId}/stocks/${tickerId}`,
      reqBody,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .catch((err) => {
      throw new Error(err)
    })
}

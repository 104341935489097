import React, { useEffect, useState } from "react"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js"
import { Line } from "react-chartjs-2"
import { Box, useMediaQuery, useTheme } from "@mui/material"
import PropTypes from "prop-types"
import { defaults } from "chart.js"

import { palette } from "../../../../utils/Palette"
import { yaxisLabel } from "../../../../utils/charts"

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)
defaults.font.family = palette.fontFamily

const Linechart = ({ values, calculatorParams }) => {
  const [chartData, setChartData] = useState(null)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "lg"))

  // Enhanced line colors
  const lineColors = {
    optimistic: {
      base: theme.palette.success.main,
      hover: theme.palette.success.dark,
      point: theme.palette.success.light,
    },
    projected: {
      base: theme.palette.primary.main,
      hover: theme.palette.primary.dark,
      point: theme.palette.primary.light,
    },
    conservative: {
      base: theme.palette.error.main,
      hover: theme.palette.error.dark,
      point: theme.palette.error.light,
    },
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 0, // Disable animations for instant updates
    },
    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: false,
          color: theme.palette.divider,
        },
        ticks: {
          font: {
            size: isMobile ? 10 : 12,
            family: theme.typography.fontFamily,
          },
          color: theme.palette.text.secondary,
          padding: 15,
        },
      },
      y: {
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          font: {
            size: isMobile ? 10 : 12,
            family: theme.typography.fontFamily,
          },
          color: theme.palette.text.secondary,
          padding: 10,
          callback: yaxisLabel,
        },
      },
    },
    interaction: {
      mode: "x",
      intersect: false,
    },
    plugins: {
      tooltip: {
        backgroundColor: theme.palette.background.paper,
        titleColor: theme.palette.text.primary,
        bodyColor: theme.palette.text.secondary,
        borderColor: theme.palette.divider,
        borderWidth: 1,
        padding: 12,
        displayColors: true,
        usePointStyle: true,
        boxPadding: 6,
        callbacks: {
          // Custom tooltip item order
          beforeBody: (context) => {
            // Sort tooltip items in our preferred order
            context.sort((a, b) => {
              const order = {
                Optimistic: 0,
                "Projected Value": 1,
                Conservative: 2,
              }
              return (
                order[a.dataset.label.split(" (")[0]] -
                order[b.dataset.label.split(" (")[0]]
              )
            })
            return []
          },
          label: function (context) {
            const label = context.dataset.label || ""
            const value = context.formattedValue || ""
            return ` ${label}: $${value}`
          },
          labelColor: function (context) {
            return {
              borderColor: "transparent",
              backgroundColor:
                lineColors[
                  context.datasetIndex === 0
                    ? "optimistic"
                    : context.datasetIndex === 1
                    ? "projected"
                    : "conservative"
                ].base,
              borderRadius: 4,
            }
          },
        },
      },
      legend: {
        position: "top",
        labels: {
          padding: 20,
          font: {
            size: isMobile ? 12 : 14,
            family: theme.typography.fontFamily,
          },
          color: theme.palette.text.primary,
          boxWidth: 12,
          usePointStyle: true,
        },
      },
      title: {
        display: true,
        text: "Projected Growth",
        font: {
          size: isMobile ? 14 : 16,
          weight: "bold",
          family: theme.typography.fontFamily,
        },
        color: theme.palette.text.primary,
        padding: {
          top: 0,
          bottom: 20,
        },
      },
    },
    elements: {
      point: {
        radius: isMobile ? 4 : 5,
        hoverRadius: 8,
        backgroundColor: (context) =>
          lineColors[
            context.datasetIndex === 0
              ? "optimistic"
              : context.datasetIndex === 1
              ? "projected"
              : "conservative"
          ].point,
        borderWidth: 2,
      },
      line: {
        tension: 0.3,
        borderWidth: isMobile ? 3 : 4,
        borderColor: (context) =>
          lineColors[
            context.datasetIndex === 0
              ? "optimistic"
              : context.datasetIndex === 1
              ? "projected"
              : "conservative"
          ].base,
        backgroundColor: "transparent",
      },
    },
  }

  useEffect(() => {
    if (!values || values.length === 0) return

    const newChartData = {
      labels: values.map((val) => val.year),
      datasets: [
        {
          label: `Optimistic (${
            parseInt(calculatorParams.rateOfReturn) +
            parseInt(calculatorParams.rateVar)
          }%)`,
          data: values.map((val) => parseInt(val.rightVar)),
          borderColor: lineColors.optimistic.base,
          backgroundColor: lineColors.optimistic.base,
          pointBackgroundColor: lineColors.optimistic.point,
          pointHoverBackgroundColor: lineColors.optimistic.hover,
        },
        {
          label: "Projected Value",
          data: values.map((val) => parseInt(val.futureVal)),
          borderColor: lineColors.projected.base,
          backgroundColor: lineColors.projected.base,
          pointBackgroundColor: lineColors.projected.point,
          pointHoverBackgroundColor: lineColors.projected.hover,
        },
        {
          label: `Conservative (${
            parseInt(calculatorParams.rateOfReturn) -
            parseInt(calculatorParams.rateVar)
          }%)`,
          data: values.map((val) => parseInt(val.leftVar)),
          borderColor: lineColors.conservative.base,
          backgroundColor: lineColors.conservative.base,
          pointBackgroundColor: lineColors.conservative.point,
          pointHoverBackgroundColor: lineColors.conservative.hover,
        },
      ],
    }

    setChartData(newChartData)
  }, [values, calculatorParams.rateOfReturn, calculatorParams.rateVar])

  return (
    <Box
      sx={{
        width: "100%",
        height: isMobile ? "380px" : "420px",
        position: "relative",
        marginBottom: isTablet ? "60px" : "40px",
      }}
    >
      {chartData && (
        <Line
          key={`line-${JSON.stringify(values)}`}
          options={options}
          data={chartData}
        />
      )}
    </Box>
  )
}

export default Linechart

Linechart.propTypes = {
  values: PropTypes.array.isRequired,
  calculatorParams: PropTypes.object.isRequired,
}

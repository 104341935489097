import React, { useState, useEffect } from "react"
import {
  Box,
  Typography,
  Grid,
  Input,
  useMediaQuery,
  useTheme,
} from "@mui/material"

import useStyles from "../styles"
import {
  InputThousandSeparator,
  displayValue,
} from "../../../utils/NumberFormatters"
import Doughnut from "./doughnut"
import MetaTags from "../../MetaTags"

const CAGR = () => {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  const [presentVal, setPresentVal] = useState(1000)
  const [futureVal, setFutureVal] = useState(2000)
  const [years, setYears] = useState(10)
  const [cagr, setCagr] = useState(7.18)

  // for meta tags
  const title = "CAGR Calculator - Lets Value Invest"
  const description =
    "The CAGR calculator can help you make informed investment decisions. Try our CAGR calculator today and see how your investment will grow!"
  const imageUrl = "https://letsvalueinvest.com/Assets/cagr-share.png"

  useEffect(() => {
    setCagr(
      ((Math.pow(futureVal / presentVal, 1 / years) - 1) * 100).toFixed(2)
    )
  }, [presentVal, futureVal, years])

  return (
    <Box className="p12" sx={{ px: { xs: 1, sm: 3 } }}>
      <MetaTags
        title={title}
        description={description}
        url={window.location.href}
        imageUrl={imageUrl}
      />
      <Grid container spacing={2}>
        {/* description */}
        <Grid item xs={12}>
          <Typography variant="body2" textAlign="justify" sx={{ mb: 2 }}>
            The CAGR calculator can help you make informed investment decisions.
            Try our CAGR calculator today and see how your investment will grow!
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="h6" textAlign="center" gutterBottom>
            <b>CAGR Calculator</b>
          </Typography>

          {/* Input fields */}
          <Grid container spacing={2}>
            {/* Present Value */}
            <Grid item xs={12}>
              <Grid container alignItems="center">
                <Grid item xs={7}>
                  <Typography className={classes.labels}>
                    Present value
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Input
                    fullWidth
                    value={presentVal}
                    inputComponent={InputThousandSeparator}
                    inputProps={{
                      style: { textAlign: "center" },
                      prefix: "$",
                      allowNegative: false,
                      onChange: (event) => {
                        setPresentVal(event.target.value)
                      },
                      thousandSeparator: true,
                    }}
                    size={isMobile ? "small" : "medium"}
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* Future Value */}
            <Grid item xs={12}>
              <Grid container alignItems="center">
                <Grid item xs={7}>
                  <Typography className={classes.labels}>
                    Future value
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Input
                    fullWidth
                    value={futureVal}
                    inputComponent={InputThousandSeparator}
                    inputProps={{
                      style: { textAlign: "center" },
                      prefix: "$",
                      allowNegative: false,
                      onChange: (event) => {
                        setFutureVal(event.target.value)
                      },
                      thousandSeparator: true,
                    }}
                    size={isMobile ? "small" : "medium"}
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* Years */}
            <Grid item xs={12}>
              <Grid container alignItems="center">
                <Grid item xs={7}>
                  <Typography className={classes.labels}>Years</Typography>
                </Grid>
                <Grid item xs={5}>
                  <Input
                    fullWidth
                    type="number"
                    value={years}
                    onChange={(event) => setYears(event.target.value)}
                    inputProps={{
                      style: { textAlign: "center" },
                      min: 1,
                    }}
                    error={years < 1}
                    size={isMobile ? "small" : "medium"}
                  />
                  {years < 1 && (
                    <Typography variant="caption" color="error">
                      Years can't be less than 1.
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* Results */}
          <Box mt={3}>
            <Typography variant="body1" gutterBottom>
              Compound Annual Growth Rate (CAGR):
              <b> {isFinite(cagr) ? cagr : 0}%</b>
            </Typography>

            <Typography variant="body2" sx={{ mt: 2, mb: 3 }}>
              This also means that if your initial investment of{" "}
              <b>{displayValue(presentVal, "$", false, false, false, false)}</b>{" "}
              turns into{" "}
              <b>{displayValue(futureVal, "$", false, false, false, false)}</b>{" "}
              in <b>{displayValue(years, "", false, false, false, false)}</b>{" "}
              years, you achieved a compound annual growth rate (CAGR) of{" "}
              <b>{displayValue(cagr, "%", true, true, false, false)}</b>
            </Typography>
          </Box>
        </Grid>

        {/* Chart */}
        <Grid
          item
          xs={12}
          sm={6}
          md={8}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: isMobile ? "300px" : "400px",
          }}
        >
          <Doughnut
            presentValue={presentVal}
            futureValue={futureVal}
            cagr={cagr}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default CAGR
